@import 'react-modal-video/css/modal-video.css';

@font-face {
  font-family: "bsl";
  src: url("fonts/brownstdlight/brownstd-light.eot");
  src: url("fonts/brownstdlight/brownstd-light.eot?#iefix") format("embedded-opentype"),
    url("fonts/brownstdlight/brownstd-light.woff2") format("woff2"),
    url("fonts/brownstdlight/brownstd-light.woff") format("woff"),
    url("fonts/brownstdlight/brownstd-light.ttf") format("truetype"),
    url("fonts/brownstdlight/brownstd-light.svg#bsl") format("svg");
}

@font-face {
  font-family: "bsr";
  src: url("fonts/brownstdregular/brownstd-regular.eot");
  src: url("fonts/brownstdlight/brownstd-light.eot?#iefix") format("embedded-opentype"),
    url("fonts/brownstdlight/brownstd-light.woff2") format("woff2"),
    url("fonts/brownstdlight/brownstd-light.woff") format("woff"),
    url("fonts/brownstdlight/brownstd-light.ttf") format("truetype"),
    url("fonts/brownstdlight/brownstd-light.svg#bsl") format("svg");
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

header .w-100,
footer .w-100,
.GoogleMap .w-100 {
  padding: 0 60px;
}

.site-header {
  background: #fff;
  padding: 15px 0 15px 0;
  position: fixed;
  left: 0;
  right: 0;
  z-index: 999;
  top: 0;
}

.navbar-nav {
  margin: 0;
  flex-direction: row !important;
}

.navbar-nav ul {
  padding-left: 0;
  margin-left: 0;
  margin-bottom: 0;
}

.navbar-nav li {
  display: inline;
}

.navbar-nav li a {
  margin: 0 20px;
  color: #242424;
  font-size: 18px;
  font-family: bsl;
  text-transform: capitalize;
  transition: 0.5s;
}

.navbar-nav li.current-menu-item a {
  color: #ec6707;
}

.navbar-nav li a:hover {
  color: #ec6707;
  text-decoration: none;
}

.navbar-nav li.current-menu-item a {
  color: #3ab54a;
  text-decoration: none;
}

#menu-item-249 a:hover {
  color: #3ab54a;
}

.header_right {
  position: relative;
}

.header_right a {
  display: inline-block;
  padding: 0 14px;
}

.header_right a svg {
  width: 16px;
  height: 16px;
  transition: 0.5s;
  opacity: 0.8;
}

.header_right a svg path {
  fill: #5b5b5b;
}

a {
  text-decoration: none !important;
}

.homeBanner {
  height: 90vh;
}

.HeroSlider .slick-slide .animatedImage{
  background-size: cover !important;
  background-position: center !important;
}

.KitchenDetail .Submenu a{
  text-transform: uppercase;
}

.homeBanner span {
  position: absolute;
  font-family: "bsl";
  color: rgba(255, 255, 255, 0.8);
  text-transform: uppercase;
  font-size: 15px;
  letter-spacing: 2px;
  bottom: 30px;
  right: 35px;
}

.nav-up {
  top: -150px;
}

.nav-down {
  top: 0;
}

.IntroText {
  padding: 250px 0 180px 0;
}

.textModule1 h3{
  color: #272727;
  font-family: bsl;
  font-size: 32px;
  font-style: normal;
  font-weight: 300;
  line-height: 130.5%; /* 41.76px */
  letter-spacing: 1.6px;
  text-transform: uppercase;
  margin-bottom: 30px;
}

.textModule1 h2 {
  font-family: "bsl";
  text-transform: uppercase;
  font-size: 25px;
  /* color: rgba(36, 36, 36, 0.8); */
  color: #272727;
  letter-spacing: 2px;
  margin-bottom: 8px;
}

.textModule1 h1 {
  font-family: "bsl";
  text-transform: uppercase;
  font-size: 25px;
  /* color: rgba(36, 36, 36, 0.8); */
  color: #272727;
  letter-spacing: 2px;
  margin-bottom: 8px;
}


.textModule1 h2:nth-last-child(4),
.Showroom h2:nth-last-child(2) {
  margin-bottom: 40px;
}

.IntroText .text-center h2:nth-child(1) {
  margin-bottom: 10px;
}

.IntroText .text-center h2:nth-child(2) {
  margin-bottom: 40px;
}

.textModule1 p {
  font-family: "bsl";
  font-size: 16px;
  color: #272727;
  /* color: rgba(36, 36, 36, 0.8); */
}

.textModule1 span {
  font-family: "bsl";
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #272727;
  /* color: rgba(36, 36, 36, 0.8); */
  margin-top: 40px;
  display: block;
}

.absoluteHeadingLeft {
  position: relative;
  top: 0;
  left: 0;
}

.absoluteHeadingLeft h2 {
  /* color: rgba(36, 36, 36, 0.8); */
  color: #272727;
  font-family: "bsl";
  text-transform: uppercase;
  letter-spacing: 1.8px;
  font-size: 60px;
  position: relative;
  z-index: 3;
}

.absoluteHeadingRight {
  position: absolute;
  top: -35px;
  right: 150px;
  z-index: 3;
}

.absoluteHeadingRight h2 {
  /* color: rgba(36, 36, 36, 0.8); */
  color: #272727;
  font-family: "bsl";
  text-transform: uppercase;
  letter-spacing: 1.8px;
  font-size: 60px;
}
.search-bar {
  padding: 10px 0;
  display: none !important;
  margin-top: 15px !important;
  /* margin-bottom: -15px; */
  background:  #f4f4f4;
}
.search-bar.open-search{
  display:flex !important;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 767px) {
  .search-bar.open-search, .open-results {
    display: none !important;
  }
}

.search-bar > .col-xl-5{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.search-icon{
  height:27px;
  margin-right: 10px;
  cursor: pointer;
  cursor: hand;
}
.clear-search-icon{
  height:27px;
  margin-right: 10px;
  cursor: pointer;
  cursor: hand;
  margin-left:-20px;
}
.search-input{
  width:100%;
  outline: none;
  border: none;
  background:#f4f4f4;
  color: #d3d4d5;
  transition: 0.5s;
}

.search-input:focus{
  color: #b3b6b8;
  transition: 0.5s;
}

.search-input::placeholder{
  color: #e3e4e5
}

.search-results{
  display:none;
  position: fixed;
  width: 100%;
  height: calc(100% - 127px);
  /* background: rgba(139, 93, 93, 0.9); */
  background: #fff;
  z-index: 99;
  padding: 25px;
  overflow-y: scroll;
  margin-bottom: 50px;
  font-family: bsl;
}

.search-results.open-results{
  display:block;
}

.results-number{
  text-align:center
}

h3.portfolio-results{
  margin: 50px 0px 30px 0;
}

/* .portfolio-results-grid{
  display: grid;
  grid-template-columns: auto auto auto;
} */

.portfolio-result{
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px;
  box-sizing: border-box;
  position: relative;
  border-radius: 3px;
}

.portfolio-search-link{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.portfolio-result > img{
  width: 64px;
  height: 64px;
  object-fit: fill;
}

.portfolio-result-location {
  color: #938c8c;
  font-weight: normal;
}

h3.results-title{
  margin-bottom: 15px;
}

.results-list{
  list-style: none;
  padding-left: 0px;
}

.results-list > li > a{
  text-decoration: none;
  color: #000;
}

.results-list > li{
  margin-bottom: 5px
}

.seperator{
  margin: 30px 0;
}

.loader {
  text-align: center;
  margin-bottom: 25px;
  color: #212529;
}

.results-grid{
  display: flex;
  justify-content: space-evenly;
}

.result-col{
  flex: 1;
}

.number-of-projects {
  text-align: center;
  color: #aaa;
  padding-bottom: 25px;
}

.mobile-search-results{
  content: '';
  width: 100%;
  height: calc(100% - 70px);
  overflow-y: scroll;
}

/* .mobile-search-results{
  -ms-overflow-style: none;  
  scrollbar-width: none;  
} */

.portfolio-results-grid.mobile{
  /* grid-template-columns: auto; */
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  /* overflow-y: scroll; */
}

.results-grid.mobile{
  flex-direction: column;
}

.results-grid.mobile > .result-col{
  margin-bottom: 15px;
}

.PortfolioRowHome .col-xl-5:nth-child(2) .PortfolioBox h3,
.PortfolioRowHome .col-xl-5:nth-child(2) .PortfolioBox span {
  margin-left: 155px;
}

.PortfolioRowHome .col-xl-5:nth-child(2) .PortfolioBox .PortfolioBoxImgSmall{
  background-position: right !important;
  background-size: contain !important;
}

.PortfolioRowHome .col-xl-5:nth-child(3) .PortfolioBox .PortfolioBoxImgSmall{
  background-position: left !important;
  background-size: contain !important;
}

.PortfolioBox .PortfolioBoxImgBig {
  height: 645px;
  transition: 1s;
}

.PortfolioBox .PortfolioBoxImgBig:hover {
  transform: scale(0.95);
  transition: 1s;
}

.PortfolioBox .PortfolioBoxImgSmall {
  height: 495px;
  transition: 0.5s;
}

.PortfolioBox .PortfolioBoxImgSmall:hover {
  transform: scale(0.95);
  transition: 0.5s;
}

.PortfolioBox h3 a {
  /* color: rgba(36, 36, 36, 0.8); */
  color: #272727;
  letter-spacing: 2px;
  font-size: 20px;
  font-family: bsl;
  text-transform: uppercase;
  margin-top: 30px;
  display: block;
  margin-right: 10%;
}

.PortfolioBox h3 a:hover {
  color: #ec6707;
  text-decoration: none;
}

.PortfolioBox:hover span {
  color: rgb(150, 150, 150);
}

.PortfolioBox span {
  color: rgba(150, 150, 150, 0.8);
  letter-spacing: 2px;
  font-size: 14px;
  font-family: bsl;
  text-transform: uppercase;
}

.SliderHome .slick-list {
  position: relative;
  overflow: hidden;
}

.SliderHome .slick-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.btn1 {
  color: #505050;
  font-size: 16px;
  font-family: "bsl";
  border: 1px solid #d9d9d9;
  display: inline-block;
  padding: 14px 40px;
  text-transform: uppercase;
  transition: 0.5s;
}

.btn1:hover {
  background: #ec6707;
  border: 1px solid transparent;
  color: #fff;
  transform: 0.5s;
}

.marginBtn {
  margin: 70px 0 140px 0;
}

.SliderHome .slick-dots {
  bottom: 110px;
}

.custom-slickdots .slick-dots li button:before {
  color: rgba(255, 255, 255, 0.8);
  font-size: 12px;
}

.custom-slickdots .slick-dots li.slick-active button:before {
  opacity: 1;
  color: #fff;
}

.textModule2HomeMargin {
  margin: 100px 0;
}

.textModule2 h2 {
  font-family: "bsl";
  /* color: rgba(36, 36, 36, 0.8); */
  color: #272727;
  font-size: 25px;
  line-height: 1.4;
  margin-bottom: 30px;
}

.textModule2 p {
  font-family: bsl;
  font-size: 16px;
  /* color: rgba(36, 36, 36, 0.8); */
  color: #272727;
  margin-bottom: 30px;
}

.textModule2 a {
  text-transform: uppercase;
  color: #ec6707;
  border-bottom: 1px solid #ec6707;
  /* text-decoration: underline !important; */
  font-family: bsl;
  font-weight: 600;
  font-size: 16px;
}

.textModule2 a:hover {
  color:#ec6707;
}

.LogosHome img {
  filter: grayscale(1);
  opacity: 0.7;
}

.LogosHome .col-xl-2 {
  text-align: center;
}

.LogosHome .col-xl-2 a {
  display: block;
}

.SliderHomeWardrobeMargin {
  margin: 120px 0;
}

.SliderText div div {
  position: relative;
}

.SliderText img{
  width: 100%;
}

.SliderText h2 {
  position: absolute;
  font-family: bsl;
  font-size: 60px;
  color: #fff;
  text-transform: uppercase;
  top: 50%;
  transform: translate(0, -50%) !important;
  width: 100%;
  text-align: center;
}

.SliderText2 div div {
  position: relative;
}

.SliderText2 .SliderTextBox {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  text-align: center;
  width: 100%;
}

.SliderText2 h2 {
  font-family: bsl;
  font-size: 60px;
  color: #fff;
  text-transform: uppercase;
  width: 100%;
}

.SliderText2 span {
  font-family: bsr;
  font-size: 20px;
  text-transform: uppercase;
  color: rgba(195, 195, 195, 0.8);
  display: inline-block;
  margin-bottom: 30px;
}

.testiBlock{
  padding-top: 60px;
}

.TestimonialHome h2 {
  font-family: bsl;
  font-size: 25px;
  /* color:rgba(36, 36, 36, 0.8); */
  color: #272727;
  line-height: 1.3;
  margin-bottom: 30px;
  position: relative;
}

.TestimonialHome h2:before {
  position: absolute;
  content: "";
  background: url(./images/testimonial-icon.png) no-repeat;
  width: 100px;
  height: 76px;
  top: -60px;
  left: 50%;
  transform: translate(-50%, 0%);
}

.TestimonialHome span {
  font-family: "bsl";
  font-weight: 600;
  text-transform: uppercase;
  color: #ec6707;
  font-size: 16px;
}

.TestimonialHomeMargin {
  margin-bottom: 80px;
  margin-top: 140px;
}

/* .lt2px {
  letter-spacing: 5px;
} */

.fsz25 {
  font-size: 25px !important;
}

.HomeCTA h2 {
  font-family: bsl;
  /* color: rgba(36, 36, 36, 0.8); */
  color: #272727;
  font-size: 25px;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.HomeCTA p {
  /* color: rgba(36, 36, 36, 0.8); */
  color: #272727;
  font-size: 20px;
  font-family: bsl;
}

.HomeCTAMargin h2 {
  margin-bottom: 30px;
}

.HomeCTA a {
  text-transform: uppercase;
  color: #ec6707;;
  text-decoration: underline !important;
  font-family: bsl;
  font-weight: 600;
  font-size: 16px;
}

.HomeCTA a:hover {
  color: #ec6707;
  text-decoration: none !important;
}

.HomeCTA_BG {
  background: #f6f6f6;
  padding: 100px 0;
  margin: 100px 0 0 0;
}

.InstaHome {
  margin: 0 0 100px 0;
}

.InstaHomeMargin h2 {
  margin-bottom: 70px;
}

.footer {
  background: #212121;
  padding: 80px 0;
}

.Footer_Links a {
  font-family: bsl;
  font-size: 20px;
  color: rgba(255, 255, 255, 0.6) !important;
  margin-bottom: 5px;
  display: inline-block;
}

.Footer_Links a:hover {
  color: #ec6707;
  color: rgb(255, 255, 255) !important;
}

.footer ul {
  padding-left: 0;
}

.footer h3 {
  font-family: bsl;
  font-size: 20px;
  color: rgba(255, 255, 255, 0.6);
  text-transform: capitalize;
}

.footer input[type="email"] {
  background: transparent;
  border: none;
  border-bottom: 2px solid #4b4b4b;
  outline: none;
  color: rgba(255, 255, 255, 0.6);
  width: 100%;
}

.footer input[type="submit"] {
  text-transform: uppercase;
  color: #fff;
  border: 2px solid #4b4b4b;
  background: transparent;
  padding: 6px 30px;
  margin-top: 30px;
  transition: 0.5s;
  font-size: 14px;
}

.footer input[type="submit"]:hover {
  background: #fff;
  color: #000;
  border: 2px solid transparent;
  transition: 0.5s;
}

.footerAddress h4 {
  font-family: bsl;
  font-size: 13px;
  color: rgba(255, 255, 255, 0.6);
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 30px;
}

.footerAddress p,
.footerAddress a {
  font-family: bsl;
  font-size: 13px;
  color: rgba(255, 255, 255, 0.6);
  display: block;
}

.footerAddress a:hover {
  color: #f1f1f1;
  text-decoration: underline !important;
}

.footerSocialIcons a{
  display: inline-block;
  margin: 0 8px;
}

.footerSocialIcons a:nth-child(1) {
  margin-left: 0;
}

.footerSocialIcons svg {
  width: 16px;
  height: 16px;
}

.footerSocialIcons svg path{
  fill: rgba(255, 255, 255, 0.3) !important;
}

.footerSocialIcons a:focus{
  color:#000;
}

.footerSocialIcons a:hover svg path, .footerSocialIcons a:focus svg path{
  fill: rgb(255, 255, 255) !important;
}

.copyright {
  font-family: bsl;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.3);
}

.copyright a{
  color: rgba(255, 255, 255, 0.5);
}

.copyright a:hover{
  color: rgba(255, 255, 255, 0.5);
}

.InnerHeaderText h1 {
  font-family: bsl;
  font-size: 60px;
  /* color: rgba(71, 72, 73, 0.8); */
  color: #272727;
  text-transform: uppercase;
  margin-left: 60px;
}

.NavFixed{
  position: relative;
}

.NavFixed ul {
  position: absolute;
  top: 0;
  left: 5%;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  z-index: 99;
}

.NavFixed ul li a {
  color: rgba(79, 79, 79, 0.8);
  font-family: bsl;
  text-transform: uppercase;
  font-size: 15px;
  margin-bottom: 12px;
  display: block;
  position: relative;
  cursor: pointer;
  transition: 0.5s;
}

.NavFixed ul li a:before {
  position: absolute;
  content: "";
  background: rgba(79, 79, 79, 0.8);
  height: 1px;
  width: 0%;
  top: 35%;
  left: -45px;
  transition: 0.5s;
}

.NavFixed ul li a.active {
  font-weight: 900;
}

.NavFixed ul li a.active:before {
  width: 35px;
  transition: 0.5s;
}

.NavFixed ul li a:hover{
  font-weight: 900;
}

.NavFixed ul li a:hover:before{
  width: 35px;
  transition: 0.5s;
}

.AboutIntroText {
  padding: 120px 0;
}

.AboutIntroText p {
  margin-bottom: 30px;
}

.AboutIntroText p:nth-last-child(1) {
  margin-bottom: 0;
}

.blockQuote blockquote {
  font-family: "bsl";
  font-style: italic;
  /* color: rgba(36, 36, 36, 0.8); */
  color: #272727;
  font-size: 25px;
  position: relative;
}

.blockQuote span {
  font-family: "bsl";
  font-weight: 900;
  text-transform: uppercase;
  /* color: rgba(36, 36, 36, 0.8); */
  color: #272727;
  font-size: 16px;
  display: block;
  margin-top: 45px;
}

.PaddingTopHeding {
  padding-top: 95px;
}

.Showroom {
  padding: 100px 0;
}

.textModule3Margin {
  padding: 0 0 100px 0;
}

.Team {
  padding: 0 0 100px 0;
}

.LogosAbout {
  margin: 0 0 100px 0;
}

.NextImage {
  margin: 100px 0;
}

.ShowroomSlider .slick-slide div div {
  text-align: center;
  outline: none;
}

.ShowroomSlider img {
  display: inline-block;
  /* width: 100%; */
}

.ShowroomSlider .slick-track {
  display: flex;
  align-items: center;
}

.ltspc7 h2 {
  letter-spacing: 2px;
}

.custom_ul1 ul {
  list-style: none;
  padding-left: 50px;
  margin-bottom: 0;
  font-family: bsl;
  font-size: 22px;
  /* color: rgba(36, 36, 36, 0.8); */
  color: #272727;
}

.custom_ul1 ul li {
  position: relative;
  margin-bottom: 12px;
}

.custom_ul1 ul li:before {
  position: absolute;
  content: "";
  background: url(./images/tick.png) no-repeat;
  width: 21px;
  height: 18px;
  top: 6px;
  left: -50px;
}

.teamBox {
  position: relative;
  outline: none;
}

.teamBox .teamTextBox {
  position: absolute;
  width: 500px;
  bottom: -160px;
  left: -170px;
}

.teamCarousel .teamBox {
  text-align: center;
  overflow: hidden;
}

.teamTextBox h4 {
  font-family: bsl;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 2px;
  /* color: rgba(36, 36, 36, 0.8); */
  color: #272727;
  opacity: 0;
}

.teamTextBox h3 {
  font-family: bsl;
  /* color: rgba(71, 72, 73, 0.8); */
  color: #272727;
  font-size: 63px;
  text-transform: uppercase;
  margin: 20px 0 15px 0;
  /* opacity: 0; */
}

.teamTextBox h3 i {
  font-style: normal;
  opacity: 0;
}

.teamTextBox p {
  opacity: 0;
}

.teamBox img {
  display: inline-block;
  transform: scale(1.2);
  transition: 0.5s;
}

.teamCarousel .slick-track .slick-current .teamBox img {
  transform: scale(1);
  transition: 0.5s;
}

.teamCarousel .slick-track {
  margin: 0 0 150px 0;
}

.teamCarousel .slick-track .slick-slide {
  opacity: 0.1;
  transition: 0.5s;
}

.teamCarousel .slick-track .slick-current {
  opacity: 1;
  transition: 0.5s;
}

.teamCarousel .slick-list {
  padding: 0 !important;
}

.teamTextBoxParent {
  position: absolute;
  bottom: 0;
  left: 23%;
  width: 500px;
  z-index: 99;
}

.teamTextBoxParent .slick-active .teamTextBox h3 i {
  -webkit-animation: tracking-in-expand 1s cubic-bezier(0.215, 0.61, 0.355, 1) both;
  animation: tracking-in-expand 1s cubic-bezier(0.215, 0.61, 0.355, 1) both;
}
.teamTextBoxParent .slick-active .teamTextBox h4 {
  animation: fade-in 2s ease forwards, slide-in-right 2s ease forwards;
}
.teamTextBoxParent .slick-active .teamTextBox p {
  animation: fade-in 2s ease forwards, slide-in-right 2s ease forwards;
}

.customArrowsBlack .slick-next {
  background: url(./images/right-arrow.png);
  width: 33px;
  height: 63px;
  right: 20px;
}

.customArrowsWhite .slick-next {
  background: url(./images/right-arrow-white.png);
  width: 33px;
  height: 63px;
  right: 40px;
}

.customArrowsBlack .slick-next:before {
  display: none;
}

.customArrowsWhite .slick-next:before {
  display: none;
}

.customArrowsWhiteBottom .slick-next {
  top: inherit;
  bottom: 0;
}

.CEOMessage .col-xl-5 img {
  width: 100%;
}

.customArrowsBlack .slick-next {
  top: 35%;
}

.KitchenDetail .thumbBox, .portfolioDetail .thumbBox{
  height: 720px;
}

.About .InnerHeaderParent, 
.WhyChoose .InnerHeaderParent, 
.Quality .InnerHeaderParent, 
.BenefitsForYou .InnerHeaderParent, 
.Blogs .InnerHeaderPlain, 
.Kitchens .Hero, 
.KitchenDetail .InnerHeaderParent{
  padding-top: 42px;
}

.Submenu {
  background: #4b4b4b;
  position: fixed;
  left: 0;
  right: 0;
  z-index: 2;
}

.Submenu a {
  font-family: "bsl";
  color: #fff;
  margin: 0 30px;
  display: block;
  font-size: 16px;
  border-bottom: 2px solid transparent;
}

.Submenu a:hover,
.Submenu li.active a {
  color: #f1f1f1;
  font-weight: 600;
  border-bottom: 2px solid #a2a2a2;
}

.SubMenuUppercase .Submenu a {
  text-transform: uppercase;
}

.Orangeh6 h6 {
  color: rgba(236, 103, 7, 1);
  font-size: 16px;
  font-family: bsl;
  text-transform: uppercase;
  margin: 30px 0;
  letter-spacing: 1px;
  line-height: 1.6;
}

.playIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s;
}

.VideoThumb .playIcon img {
  transform: scale(1);
  transition: 0.5s;
}

.VideoThumb:hover .playIcon img {
  transform: scale(1.2);
  transition: 0.5s;
}

.VideoThumb .playIcon {
  width: 120px;
  height: 120px;
  background: radial-gradient(rgba(236, 95, 7, 0.4) 60%, rgba(236, 95, 7, 0.4) 69%);
  border-radius: 50%;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.VideoThumb .playIcon::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translateX(-40%) translateY(-50%);
  transform: translateX(-40%) translateY(-50%);
  transform-origin: center center;
  width: 0;
  height: 0;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-left: 25px solid #fff;
  z-index: 100;
  -webkit-transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.VideoThumb .playIcon:before {
  content: "";
  position: absolute;
  width: 150%;
  height: 150%;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
  -webkit-animation: pulsate1 2s;
  animation: pulsate1 2s;
  -webkit-animation-direction: forwards;
  animation-direction: forwards;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: steps;
  animation-timing-function: steps;
  opacity: 1;
  border-radius: 50%;
  border: 5px solid #ec3d07;
  top: -26%;
  left: -26%;
  background: #ec3d07;
  transform: translate(-50%, -50%);
}

@keyframes pulsate1 {
  0% {
    -webkit-transform: scale(0.6);
    transform: scale(0.6);
    opacity: 1;
    box-shadow: inset 0px 0px 25px 3px #ec3d07, 0px 0px 25px 10px #ec3d07;
  }
  100% {
    -webkit-transform: scale(1, 1);
    transform: scale(1);
    opacity: 0;
    box-shadow: none;
  }
}

.HistoryImg {
  position: relative;
}

.HistoryImg:after {
  position: absolute;
  content: "";
  background: #c4c4c4;
  height: 5px;
  width: 100%;
  top: 50%;
  z-index: 1;
}

.HistoryImg img {
  position: relative;
  z-index: 2;
}

.HistoryBoxText h5 {
  font-family: bsl;
  /* color: rgba(36, 36, 36, 0.8); */
  color: #272727;
  text-transform: uppercase;
  font-size: 15px;
  letter-spacing: 2px;
  line-height: 1.5;
  margin-bottom: 12px;
}

.HistoryBoxText p {
  font-family: bsl;
  font-size: 14px;
  /* color: rgba(36, 36, 36, 0.8); */
  color: #272727;
}

.HistoryBoxTextMargin {
  margin-top: 30px;
}

.HistorySlider{
  display: none;
}

.HistorySliderMargin {
  padding: 120px 0;
}

.Imgbg {
  position: relative;
}

.Imgbg:after {
  position: absolute;
  content: "";
  background: #ebebeb;
  height: 100%;
  width: 100%;
  left: -60px;
  top: -60px;
  z-index: -1;
}

.ThreeBlocks span {
  position: relative;
  padding-left: 30px;
}

.ThreeBlocks span:before {
  position: absolute;
  content: "";
  background: #d0d0d0;
  height: 120%;
  width: 3px;
  top: -15%;
  left: 0;
}

.TextModuleRightImage1Margin {
  padding: 160px 0;
}

.FourBlocksImg img {
  width: 100%;
}

.FourBlocksImg span {
  padding-left: 0;
}

.FourBlocksImg span:before {
  display: none;
}

.FourBlocksImg .col {
  flex: 0 0 20%;
  margin-right: 30px;
}

.FourBlocksImg .col:nth-last-child(1) {
  margin-right: 0;
}

.custom_ul1-18 ul {
  font-size: 16px;
  padding-left: 40px;
}

.custom_ul1-18 ul li:before {
  background-size: 15px;
  width: 17px;
  height: 14px;
  left: -40px;
}

.TextModuleLeftImage2Margin {
  padding: 180px 0 80px 0;
}

.TextModuleLeftImage1Margin {
  padding: 160px 0 40px 0;
}

.QualityIntroMargin {
  padding: 120px 0;
}

.QualityNav ul {
  list-style: none;
}

.QualityNav li {
  position: absolute;
}

.QualityNav ul li a {
  font-family: bsl;
  font-weight: 300;
  color: rgba(79, 79, 79, 0.8);
  text-transform: uppercase;
  letter-spacing: 2px;
  display: inline-block;
  line-height: 1;
  font-size: 24px;
  border: 1px solid transparent;
  padding: 15px 30px 10px 30px;
}

.QualityNav ul li a:hover {
  font-weight: 600;
  border: 1px solid #c4c4c4;
}

.QualityNav ul li:nth-child(1) {
  top: -10px;
  left: 180px;
}

.QualityNav ul li:nth-child(2) {
  top: 40px;
  left: 50%;
  transform: translate(-60%, 0%);
}

.QualityNav ul li:nth-child(3) {
  right: 25%;
  top: 50px;
}

.QualityNav ul li:nth-child(4) {
  bottom: 0;
  left: 31%;
}

.QualityNav ul li:nth-child(5) {
  bottom: 0;
  left: 47%;
}

.QualityNav ul li:before {
  position: absolute;
  content: "";
  background: #ec6707;
  width: 1px;
  height: 200px;
}

.QualityNav ul li:hover:before {
  background: #000;
}

.QualityNav ul li:after {
  position: absolute;
  content: "";
  background: #ec6707;
  width: 12px;
  height: 12px;
  border-radius: 100%;
}

.QualityNav ul li:hover:after {
  background: #000;
}

.QualityNav ul li:nth-child(1):before {
  top: 60px;
  left: 73px;
  height: 150px;
}

.QualityNav ul li:nth-child(1):after {
  top: 205px;
  left: 68px;
}

.QualityNav ul li:nth-child(2):before {
  top: 60px;
  left: 103px;
  height: 150px;
}

.QualityNav ul li:nth-child(2):after {
  top: 205px;
  left: 98px;
}

.QualityNav ul li:nth-child(3):before {
  top: 60px;
  left: 90px;
  height: 220px;
}

.QualityNav ul li:nth-child(3):after {
  top: 275px;
  left: 85px;
}

.QualityNav ul li:nth-child(4):before {
  top: -185px;
  left: 95px;
  height: 180px;
}

.QualityNav ul li:nth-child(4):after {
  top: -190px;
  left: 89px;
}

.QualityNav ul li:nth-child(5):before {
  top: -175px;
  left: 100px;
  height: 170px;
}

.QualityNav ul li:nth-child(5):after {
  top: -180px;
  left: 94px;
}

.QualitySection {
  position: relative;
}

.QualitySectionMargin {
  padding: 80px 0 40px 0;
  margin: 40px 0 40px 0;
}

.QualitySectionBorder:before {
  position: absolute;
  content: "";
  background: #a2a2a2;
  height: 1px;
  width: 90%;
  left: 20%;
  top: 0;
  transform: translate(-17%, 0);
  /* border-top: 1px solid #a2a2a2; */
}

.QualityThreeCols img {
  width: 100%;
}

.QualityThreeColsText h3 {
  font-family: bsl;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin: 30px 0 20px 0;
}

.QualityThreeColsText p {
  margin-bottom: 0;
}

a.textUnderline {
  font-size: 16px;
  letter-spacing: 2px;
  /* color: rgba(36, 36, 36, 0.5); */
  color: #272727;
  text-transform: uppercase;
  border-bottom: 1px solid #272727;
}

a.textUnderline:hover{
  color:#000;
  border-bottom: 1px solid #000;
}

.BenefitsMargin {
  margin-top: 120px;
}
.imgHeightBenefits {
  height: 100%;
}
.BenefitsBlocks img {
  height: 100%;
  object-fit: cover;
}
.FullWidthRightImage_textBox h2 {
  margin-bottom: 30px;
}

.fulwidth_Tabs .nav-tabs .nav-link {
  /* color: rgba(36, 36, 36, 0.8); */
  color: #272727;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 20px;
  border-bottom: 1px solid #c5c5c5;
  border: none;
  padding: 30px 40px;
  width: 100%;
  outline: none;
}

.fulwidth_Tabs .nav-tabs .nav-link.active {
  border-bottom: 4px solid #8a8a8a;
}

.fulwidth_TabsMargin {
  margin: 120px 0 160px 0;
}

.btnBorder {
  font-family: "bsl";
  border: 1px solid #c5c5c5;
  display: inline-block;
  padding: 11px 35px;
  color: #505050;
  text-transform: uppercase;
  font-size: 14px;
}

.btnBorder:hover {
  color: #000 !important;
  background-color: #c5c5c5;
}

.btnBorderMargin .btnBorder {
  margin-top: 30px;
}

.WhatsappIcon {
  position: fixed;
  right: 60px;
  bottom: 40px;
  width: 72px;
  transition: 0.5s;
  display: none;
}

.WhatsappIcon:hover {
  opacity: 0.8;
  transition: 0.5s;
}

.SingleURL .double-url {
  display: none;
}

.DoubleURL .single-url {
  display: none;
}

.InnerHeaderPlain h1 {
  font-family: "bsl";
  text-transform: uppercase;
  font-size: 38px;
  /* color: rgba(36, 36, 36, 0.8); */
  color: #272727;
  letter-spacing: 2px;
  margin-bottom: 20px;
}

.InnerHeaderPlain p {
  font-family: "bsl";
  font-size: 14px;
  /* color: rgba(36, 36, 36, 0.8); */
  color: #272727;
  margin-bottom: 0;
}

.LogosHome .row .col-xl-2:nth-last-child(2) img {
  position: relative;
  top: -6px;
}

.InnerHeaderPlainMargin {
  margin: 150px 0;
}

.postExcerpt .more-link {
  display: none;
}

.postThumb img {
  width: 100%;
}

.postTitle {
  font-family: bsl;
  font-size: 20px;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin: 30px 0 15px 0;
  display: block;
  line-height: 1.6;
  /* color: rgba(36, 36, 36, 0.8); */
  color: #272727;
  min-height: 64px;
}

.postTitle:hover {
  /* color: rgb(36, 36, 36); */
  color: #272727;
}

.postDetails p {
  font-family: "bsl";
  font-size: 14px;
  /* color: rgba(36, 36, 36, 0.8); */
  color: #272727;
  margin-bottom: 0;
  line-height: 1.7;
}

.postReadmore {
  font-family: bsl;
  font-size: 14px;
  color: rgba(236, 103, 7, 0.7);
  margin-top: 25px;
  text-transform: uppercase;
  display: inline-block;
  position: relative;
}

.postReadmore:hover {
  color: rgba(236, 103, 7, 0.7);
}

.postReadmore:before {
  position: absolute;
  content: "";
  background: rgba(236, 103, 7, 0.7);
  width: 100%;
  height: 1px;
  left: 0;
  bottom: 0;
  transition: 0.5s;
}

.postReadmore:hover:before {
  position: absolute;
  content: "";
  background: rgba(236, 103, 7, 1);
  width: 0%;
  height: 1px;
  left: 0;
  bottom: 0;
  transition: 0.5s;
}

.postsMargin .col-xl-4 {
  margin-bottom: 80px;
}

.postContentTitle {
  font-family: bsl;
  font-size: 32px;
  /* color: rgba(36, 36, 36, 0.8); */
  color: #272727;
  letter-spacing: 2px;
  line-height: 1.4;
  text-transform: uppercase;
  /* margin-bottom: 30px; */
}

.postedDate {
  font-family: "bsl";
  font-size: 14px;
  /* color: rgba(36, 36, 36, 0.4); */
  color: #272727;
}

.postHeaderMargin {
  margin: 50px 0 30px 0;
}

.postContent p,
.postContent ul,
.postContent ol,
.postContent p span {
  text-align: left;
}

.postContent {
  font-family: bsl;
  font-size: 16px;
  /* color: rgba(36, 36, 36, 0.7); */
  color: #272727;
}

.postContent h1:nth-child(1), .postContent h1:nth-child(2){
  display: none !important;
}

.Category36 .Submenu li:nth-child(1) a{
  color: #f1f1f1;
  font-weight: 600;
  border-bottom: 2px solid #a2a2a2;
}

.Category37 .Submenu li:nth-child(2) a{
  color: #f1f1f1;
  font-weight: 600;
  border-bottom: 2px solid #a2a2a2;
}

.Category38 .Submenu li:nth-child(3) a{
  color: #f1f1f1;
  font-weight: 600;
  border-bottom: 2px solid #a2a2a2;
}

.postContent .size-full {
  margin-bottom: 70px;
  margin-top: 70px;
  width: 100%;
  height: 100%;
}

.thumbBox .video, .VideoThumb, .VideoThumb a{
  height: 100%;
}

.postContent a{
  color: #ec6707;
}

.postContent a:hover{
  text-decoration: underline !important;
  color: #ec6707;
}

.btnBorderBig {
  font-family: "bsl";
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 2px;
  /* color: rgba(36, 36, 36, 0.8); */
  color: #272727;
  border: 1px solid #a2a2a2;
  padding: 25px 60px 23px 60px;
  display: inline-block;
  line-height: 1;
  transition: 0.5s;
}

.btnBorderBig:hover {
  background: #ec6707;
  color: #fff;
  transition: 0.5s;
}

.postBigBtn {
  padding: 20px 0 120px 0;
}

.SocialMediaIconsMargin {
  padding: 80px 0;
}

.contactSocialIcons svg path {
  fill: rgba(26, 26, 26, 0.23) !important;
}

.contactSocialIcons a:hover svg path {
  fill: rgb(26, 26, 26) !important;
}

.contactSocialIcons a {
  top: -4px !important;
  position: relative;
}

.contact_form {
  border: 2px solid #f4f4f4;
  box-shadow: 10px 10px 40px rgba(129, 137, 146, 0.13);
  padding: 55px 60px;
}

.contact_form.contactFormPopup {
  padding: 40px;
}

.contact_form h2 {
  font-family: bsl;
  font-size: 25px;
  /* color: rgba(36, 36, 36, 0.8); */
  color: #272727;
  -webkit-font-smoothing: antialiased;
}

.contact_form p {
  font-family: bsl;
  font-size: 16px;
  /* color: rgba(36, 36, 36, 0.8); */
  color: #272727;
  margin-bottom: 20px;
  -webkit-font-smoothing: antialiased;
}

.contact_form label {
  font-family: bsl;
  /* color: rgba(36, 36, 36, 0.8); */
  color: #272727;
  font-size: 14px;
  width: 100%;
  -webkit-font-smoothing: antialiased;
}

.contact_form input,
.contact_form textarea, 
.contact_form select {
  background: #f9f9fc;
  border: 1px solid #eaeef6;
  padding: 8px 8px;
  width: 100%;
  margin-top: 5px;
  color: rgba(36, 36, 36, 0.8);
  outline: none;
}

.contact_form select{
  /* margin-bottom: 15px; */
  color: rgba(36, 36, 36, 0.6);
  background:url(./svgs/arrow-down.svg) no-repeat #f9f9fc;
  background-position: right 10px center;
  font-size: 14px;
  padding: 11px 8px;
}

.contact_form select:focus{
  box-shadow:none !important;
  border-color: #eaeef6;
  color: rgba(36, 36, 36, 0.6);
  background:url(./svgs/arrow-down.svg) no-repeat #f9f9fc;
  background-position: right 10px center;
}

.contact_form textarea {
  height: 80px;
}

.contact_form input[type="submit"] {
  background: #ec6707;
  color: #f6fafd;
  font-size: 16px;
  font-family: bsl;
  text-transform: uppercase;
  padding: 25px 0;
  cursor: pointer;
  outline: none;
  margin-top: 0;
  transition: 0.5s;
}

.contact_form input[type="submit"]:hover {
  border: none;
  background: #333;
  transition: 0.5s;
}

.contact_submit_arrow {
  position: relative;
}

.contact_submit_arrow img {
  position: absolute;
  top: 50%;
  left: 32%;
  mix-blend-mode: screen;
  transform: translate(-50%, -52%);
}

.contact_submit_arrow br {
  display: none;
}

.intro_text_contact {
  position: relative;
  padding-bottom: 40px;
  margin-bottom: 40px;
}

.intro_text_contact:after {
  position: absolute;
  content: "";
  background: #dadada;
  height: 1px;
  width: 90%;
  bottom: 0;
}

.intro_text_contact h2 {
  font-size: 35px;
  /* color: rgba(36, 36, 36, 0.8); */
  color: #272727;
  text-transform: inherit;
  font-family: bsl;
  margin-bottom: 0;
  -webkit-font-smoothing: antialiased;
}

.intro_text_contact h1 {
  font-size: 35px;
  /* color: rgba(36, 36, 36, 0.8); */
  color: #272727;
  text-transform: inherit;
  font-family: bsl;
  margin-bottom: 0;
  -webkit-font-smoothing: antialiased;
}

.intro_text_kitchens {
  position: relative;
  padding-top: 40px;
  /* padding-bottom: 40px; */
  text-align: center;
}

.intro_text_kitchens h1 {
  font-size: 35px;
  /* color: rgba(36, 36, 36, 0.8); */
  color: #272727;
  text-transform: inherit;
  font-family: bsl;
  margin-bottom: 0;
  -webkit-font-smoothing: antialiased;
}





.contact_page_detail a {
  font-size: 16px;
  color: rgba(36, 36, 36, 0.8);
  font-family: bsl;
  -webkit-font-smoothing: antialiased;
  display: flex;
  margin-bottom: 15px;
}

.contact_page_detail a:hover {
  /* color: rgb(36, 36, 36); */
  color: #272727;
}

.contact_page_detail a:hover svg path {
  fill: rgb(26, 26, 26);
}

.contact_page_detail a svg {
  width: 18px;
  margin-right: 10px;
  position: relative;
  top: -1px;
}

.contact_page_detail li:nth-last-child(1) a svg {
  width: 14px;
}

.contact_page_detail a svg path {
  fill: rgba(26, 26, 26, 0.23);
}

.ContactInfoForm {
  padding: 100px 0;
}

.brand-color {
  color: #ec6707 !important;
}

.PageLoader .d-flex {
  height: 900px;
}

.close {
  position: absolute;
  top: -28px;
  right: -3px;
  border-radius: 100%;
  background: transparent;
  border: none;
}

.close svg {
  position: relative;
  top: -3px;
  width: 20px;
  height: 20px;
}

.close svg path {
  fill: #fff;
}

.VideoModal iframe {
  margin-bottom: -7px;
}

.VideoModal .modal-dialog {
  max-width: 900px;
}

.About .Submenu ul li:nth-child(5),
.WhyChoose .Submenu ul li:nth-child(5),
.Quality .Submenu ul li:nth-child(5),
.BenefitsForYou .Submenu ul li:nth-child(5) {
  display: none;
}

.QualitySectionList .QualitySection:nth-last-child(2) .VideoThumb {
  display: none;
}

.contactSocialIcons span {
  margin-top: 0;
}

.TopIntroMargin {
  padding: 60px 0;
}

.portfolioDetail .KitchenProBox h3{
  font-size: 25px;
}
.portfolioDetail .KitchenProBox h1{
  font-size: 25px;
}


.KitchenProBox h3 {
  font-family: bsl;
  color: #272727;
  /* opacity: 0.8; */
  font-size: 20px;
  letter-spacing: 1.8px;
  text-transform: uppercase;
}

.KitchenProBox h1 {
  font-family: bsl;
  color: #272727;
  /* opacity: 0.8; */
  font-size: 20px;
  letter-spacing: 1.8px;
  text-transform: uppercase;
}

.KitchenProBox span {
  font-family: bsl;
  text-transform: uppercase;
  color: #242424;
  /* opacity: 0.8; */
  letter-spacing: 1.8px;
  font-size: 16px;
  margin-top: 0;
}

.KitchenProBox_TextBoxMargin {
  position: relative;
  margin-bottom: 40px;
  padding-bottom: 40px;
}

.KitchenProBox_TextBoxMargin:after {
  position: absolute;
  content: "";
  background: #d4d4d4;
  height: 2px;
  width: 10%;
  bottom: 0;
  left: 0;
}

.LeftRightBoxes .row:nth-child(even) .col-12.col-sm-6:nth-child(1) {
  order: 2;
}

.LeftRightBoxesMargin {
  margin-top: 60px;
  padding-top: 60px;
}

.GalleryLayout img {
  width: 100%;
}


.before-after img {
  aspect-ratio: 1104/623;
  object-fit: fill;
  padding: 0px calc(var(--bs-gutter-x) * .5);
}
.before-after-arrows{
  display: flex;
  align-items: center;
  justify-content: center;
}
.before-after-arrows svg{
  position: absolute;
}
.before-after-arrows svg:first-child{
  right: 10px;
}
.before-after-arrows svg:last-child{
  left: 10px;
}

.productIntroText {
  /* color: rgba(36, 36, 36, 0.8); */
  color: #272727;
  font-family: "bsr";
  text-transform: uppercase;
  letter-spacing: 2px;
}

.productIntroText h1 {
  font-size: 30px;
}

.productIntroText h3 {
  font-size: 14px;
}

.KitchenProBoxSingle h3 {
  margin-bottom: 0;
}

.boldSpan span {
  font-family: "bsr";
  font-weight: 900;
}

.OrangeH6mb-20 h6 {
  margin-bottom: 20px;
}

.squareDots .slick-dots {
  position: relative;
  bottom: inherit;
  text-align: left;
}

.squareDotsMargin .slick-dots {
  margin: 20px 0 40px 0;
}

.slick-dots li {
  margin: 0 15px !important;
  width: 20px !important;
  height: 4px !important;
}

.slick-dots li:nth-child(1) {
  margin-left: 0 !important;
}

.slick-dots li button {
  width: 20px !important;
  height: 4px !important;
}

.slick-dots li button:before {
  content: "";
  width: 40px !important;
  height: 4px !important;
  background: #aeaeae;
  color:transparent!important;
  border-radius: 0;
}

.slick-dots li.slick-active button:before {
  background: rgba(236, 103, 7, 0.8);
}

.enquireBtn {
  font-family: bsl;
  font-size: 14px;
  color: #242424;
  display: inline-block;
  padding: 10px 35px;
  border: 1px solid #bbbbbb;
  text-transform: uppercase;
  transition: 0.5s;
  cursor: pointer;
}

.enquireBtn:hover {
  color: #fff !important;
  background: rgba(236, 103, 7, 0.8);
  transition: 0.5s;
}

.underlineBtn {
  font-family: bsl;
  font-size: 14px;
  text-transform: uppercase;
  /* color: rgba(36, 36, 36, 0.8); */
  color: #272727;
  border-bottom: 1px solid rgba(39, 39, 39, 0.4);
  /* border-bottom: 1px solid rgba(36, 36, 36, 0.4); */
  line-height: 1;
  display: inline-block;
  cursor: pointer;
}

.underlineBtn:hover {
  border-bottom: 1px solid transparent;
  color: #242424;
  /* border-bottom: 1px solid rgba(36, 36, 36, 0.9); */
  border-bottom: 1px solid rgba(39, 39, 39, 0.9);
}

.galleryRow {
  margin-bottom: 50px;
}

.twoColImage a{
  height: 445px;
}

.twoColImage a img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.galleryRow a{
  width: 100%;
  display: block;
}

.GalleryLayout .galleryRow:nth-last-child(1) {
  margin-bottom: 0;
}

.FullWidthBtn a {
  font-family: bsl;
  text-transform: uppercase;
  /* color: rgba(36, 36, 36, 0.4); */
  color: rgba(39, 39, 39, 0.4);
  font-size: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  letter-spacing: 3px;
  background: #f1f1f1;
  text-align: center;
  line-height: 1;
  padding: 60px 0;
  font-weight: 600;
  transition: 0.5s;
}

.FullWidthBtn a:hover {
  color: #fff;
  background: #4b4b4b;
  transition: 0.5s;
}

.FullWidthBtnIcon {
  width: 23px;
  height: 23px;
  margin-right: 30px;
  transition: 0.5s;
}

.FullWidthBtn a:hover svg path {
  fill: #fff;
  transition: 0.5s;
}

/* .kitchenCta__text h2 {
  font-size: 24px;
} */

/* .kitchenCta__text p {
  font-size: 21px;
} */

.hideImage img {
  display: none;
}

.KitchenProBox img.icon {
  width: 16px;
  opacity: 0.3;
  position: relative;
  top: -2px;
  margin-right: 7px;
}

.no-portfolios {
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.portfolioImg{
  height: 360px;
}

.portfolio-thumb {
  object-fit: cover;
}

.col-s-12 > a > .portfolio-thumb {
  /* height: 320px; */
  object-fit: fill;
}

@media screen and (max-width: 576px) {
  .col-s-12 > a > .portfolioImg {
    height: 270px;
    object-fit: fill;
  }
}
@media screen and (max-width: 340px) {
  .col-s-12 > a > .portfolioImg {
    height: 250px;
    object-fit: fill;
  }
}

.portfolioAttr {
  display: flex;
}

.portfolioAttr span {
  text-transform: capitalize;
  letter-spacing: 0;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.circleAttr {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  margin-right: 15px;
}

.circleAttr-Brown {
  background: #593117;
}

.circleAttr-Gray {
  background: #544e4e;
}

.WardrobeBoxModal .modal-dialog {
  max-width: 1000px;
}

.WardrobeBoxModal a {
  text-decoration: none !important;
  /* color: rgba(36, 36, 36, 0.8); */
  color: #272727;
}

.underlineBtnIcon {
  width: 15px;
  height: 15px;
  opacity: 0.6;
}

.WardrobeBoxModal .modal-content {
  border-radius: 0;
}

.WardrobeBoxModal .contact_form {
  border: none;
  box-shadow: none;
}

.PopupSlider .slick-next:before,
.PopupSlider .slick-prev:before {
  display: none;
}

.PopupSlider .slick-prev,
.PopupSlider .slick-next {
  width: 40px;
  height: 40px;
  background-size: 100%;
  z-index: 2;
}

.PopupSlider .slick-prev {
  background: url(./images/right-arrow.svg);
  transform: rotate(180deg);
  left: 15px;
}

.PopupSlider .slick-next {
  background: url(./images/right-arrow.svg);
  right: 20px;
}

.PopupSlider .slick-next:before,
.PopupSlider .slick-next:after {
  display: none;
}

/* .WardrobeBoxModal .close {
  display: none !important;
} */

.pagination li,
.pagination li a {
  color: #242424;
}

.pagination li.active {
  background: #251d17;
  color: #fff;
}

.paginationNew{
  display: flex;
  list-style: none;
  font-family: bsl;
  padding-left: 0;
  margin-left: 0;
  margin-bottom: 40px;
  margin-top: 40px;
}

.paginationNew li{
  margin: 0 2px;
}

.paginationNew li a{
  color: #242424;
  display: block;
  padding: 4px 14px;
  background: #f1f1f1;
}

.Footer_Links li:nth-child(1) a{
  margin-top: -12px;
}

.paginationNew li a:hover{
  background: #251d17;
  color: #fff !important;
}

.paginationNew li.selected a{
  background: rgba(236, 103, 7, 0.7);
  color: #fff;
}

.WardrobeBox img {
  width: 100%;
  border-radius: 10px;
}

.filter-dropdown {
  cursor: pointer;
  cursor: hand;
}

.Filter2Cols li {
  flex: 0 0 50% !important;
}

.FilterHead {
  margin-bottom: 20px;
  display: block;
  font-family: bsr;
  font-weight: 600;
  font-size: 15px;
  position: relative;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding-bottom: 10px;
}

.FilterHeadActive {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding-bottom: 10px;
}

.FilterHeadActive:after {
  transform: rotate(180deg) !important;
  top: 7px !important;
  transition: 0.5s;
}

.FilterHead:after {
  position: absolute;
  content: "";
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE5LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPg0KPHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB2aWV3Qm94PSIwIDAgNDkwLjY4OCA0OTAuNjg4IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA0OTAuNjg4IDQ5MC42ODg7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxwYXRoIHN0eWxlPSJmaWxsOiNGRkMxMDc7IiBkPSJNNDcyLjMyOCwxMjAuNTI5TDI0NS4yMTMsMzQ3LjY2NUwxOC4wOTgsMTIwLjUyOWMtNC4yMzctNC4wOTMtMTAuOTktMy45NzUtMTUuMDgzLDAuMjYyDQoJYy0zLjk5Miw0LjEzNC0zLjk5MiwxMC42ODcsMCwxNC44MmwyMzQuNjY3LDIzNC42NjdjNC4xNjUsNC4xNjQsMTAuOTE3LDQuMTY0LDE1LjA4MywwbDIzNC42NjctMjM0LjY2Nw0KCWM0LjIzNy00LjA5Myw0LjM1NC0xMC44NDUsMC4yNjItMTUuMDgzYy00LjA5My00LjIzNy0xMC44NDUtNC4zNTQtMTUuMDgzLTAuMjYyYy0wLjA4OSwwLjA4Ni0wLjE3NiwwLjE3My0wLjI2MiwwLjI2Mg0KCUw0NzIuMzI4LDEyMC41Mjl6Ii8+DQo8cGF0aCBkPSJNMjQ1LjIxMywzNzMuNDE1Yy0yLjgzMSwwLjAwNS01LjU0OC0xLjExNS03LjU1Mi0zLjExNUwyLjk5NCwxMzUuNjMzYy00LjA5My00LjIzNy0zLjk3NS0xMC45OSwwLjI2Mi0xNS4wODMNCgljNC4xMzQtMy45OTIsMTAuNjg3LTMuOTkyLDE0LjgyLDBsMjI3LjEzNiwyMjcuMTE1bDIyNy4xMTUtMjI3LjEzNmM0LjA5My00LjIzNywxMC44NDUtNC4zNTQsMTUuMDgzLTAuMjYyDQoJYzQuMjM3LDQuMDkzLDQuMzU0LDEwLjg0NSwwLjI2MiwxNS4wODNjLTAuMDg2LDAuMDg5LTAuMTczLDAuMTc2LTAuMjYyLDAuMjYyTDI1Mi43NDQsMzcwLjI3OQ0KCUMyNTAuNzQ4LDM3Mi4yODEsMjQ4LjAzOSwzNzMuNDA4LDI0NS4yMTMsMzczLjQxNXoiLz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjwvc3ZnPg0K);
  background-size: 100%;
  width: 10px;
  height: 10px;
  background-repeat: no-repeat;
  right: 12px;
  top: 50%;
  transition: 0.5s;
  transform: translate(0%, -70%);
}

.FilterList li input {
  margin-right: 8px;
}

.FilterList .list-inline {
  height: auto;
  transition: 0.5s;
}

.h-0 {
  height: 0px;
  transition: 0s;
  visibility: hidden;
  list-style: none;
}

.Filters h5 {
  font-family: bsl;
  text-transform: uppercase;
  color: #545454;
}

.reset {
  font-family: bsl;
  font-size: 14px;
  color: #545454;
  text-transform: uppercase;
  opacity: 0.6;
  cursor: pointer;
}

.FilterList label {
  font-family: "bsl";
  color: #545454;
  opacity: 0.7;
  font-size: 12px;
  display: flex;
  align-items: center;
  cursor: pointer;
  text-transform: capitalize;
}

.checkbox {
  position: relative;
  margin-bottom: 8px;
}

.checkbox label input[type="checkbox"]:checked + .cr > .cr-icon {
  transform: scale(1) rotateZ(0deg);
  opacity: 1;
}

.checkbox label input {
  display: none !important;
}

.checkbox .cr {
  position: relative;
  /* display: inline-block; */
  border: 1px solid #a9a9a9;
  border-radius: 0.25em;
  width: 1.3em;
  height: 1.3em;
  margin-right: 0.5em;
}

.checkbox .cr .cr-icon,
.radio .cr .cr-icon {
  position: absolute;
  font-size: 0.6em;
  line-height: 0;
  top: 59%;
  left: 14%;
}

.checkbox .cr {
  position: relative;
  display: inline-block;
  border: 1px solid #a9a9a9;
  border-radius: 0;
  width: 1em;
  height: 1em;
  /* float: left; */
  margin-right: 0.5em;
  margin-top: -2px;
  float: none;
}

.checkbox label input[type="checkbox"] + .cr > .cr-icon,
.radio label input[type="radio"] + .cr > .cr-icon {
  transform: scale(1) rotateZ(190deg);
  opacity: 0;
  transition: all 0.3s ease-in;
}

.checkbox label input[type="checkbox"]:checked {
  font-weight: 600;
}

.fa-check:before {
  content: "";
  background: url(./images/check.png) no-repeat;
  display: block;
  width: 10px;
  height: 8px;
  top: -5px;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  font-size: 20px;
}

/* .Hero .slick-list .slick-track .slick-slide div div{
  background: #000;
} */

.Hero .slick-list,
.Hero .slick-list .slick-track,
.Hero .slick-list .slick-track .slick-slide,
.Hero .slick-list .slick-track .slick-slide div {
  height: 100% !important;
}

.tracking-in-expand {
  -webkit-animation: tracking-in-expand 1s cubic-bezier(0.215, 0.61, 0.355, 1) both;
  animation: tracking-in-expand 1s cubic-bezier(0.215, 0.61, 0.355, 1) both;
}
@-webkit-keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}
@keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}
.fade-in-fwd {
  -webkit-animation: fade-in-fwd 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-fwd 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}
@-webkit-keyframes fade-in-fwd {
  0% {
    -webkit-transform: translateZ(-80px);
    transform: translateZ(-80px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}
@keyframes fade-in-fwd {
  0% {
    -webkit-transform: translateZ(-80px);
    transform: translateZ(-80px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}
@keyframes slide-right--100 {
  0% {
    transform: translate(100%);
  }
  to {
    transform: translate(0);
  }
}
.slide-in-right {
  -webkit-animation: slide-in-right 1s ease-in both;
  animation: slide-in-right 1s ease-in both;
}
@-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-right {
  0% {
    transform: translate(25px);
  }
  to {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transform: translate(0);
  }
}
@keyframes slide-left--20 {
  0% {
    transform: translate(0);
  }
  to {
    transform: translate(25%);
  }
}

._wPDyp{
  flex: 0 0 24%
}

.imgMagnify{
  position: relative;
  overflow: hidden;
}

.imgMagnify img{
  -webkit-transform: scale(1);
  transform: scale(1);
  transition: 5s;
}

.imgMagnify img:hover{
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  transition: 5s;
}

.WardrobeBoxModal .PopupSlider .slick-slide div{
  width:100%;
  height: 400px;
  margin: auto;
}

.WardrobeBoxModal .PopupSlider .slick-slide div img{
  object-fit: cover;
  width: 100%;
  height:100%;
  margin: 10px 0;
}

.PopupSlider img{
  border-radius: 0;
}

.react-tel-input .form-control{
  width: 100%;
  border:1px solid #eaeef6 !important;
  border-right:none !important;
  border-left:none !important;
  border-radius: 0;
  background: #f9f9fc;
  padding: 8px;
  height: 39px;
  padding-left: 50px;
  margin-top: 6px !important;
  outline: none !important;
}

.react-tel-input .form-control:focus{
  outline: none !important;
  box-shadow: none !important;
  background: #f9f9fc;
}

.react-tel-input .flag-dropdown{
  border-right: 0;
  border:1px solid #eaeef6;
  background: #f9f9fc;
}

.VideoBanner{
  position: relative;
  overflow: hidden;
  height: 700px;
}

.VideoBanner video{
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.goTop{
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 99;
  cursor: pointer;
  background: url(./images/right-arrow-white.png) #4b4b4b no-repeat center;
  background-size: 30%;
  width: 30px;
  height: 42px;
  transform: rotate(-90deg);
}

.rh5v-DefaultPlayer_controls{
  top:0;
  bottom: inherit;
}

video::-webkit-media-controls-timeline {
  display: none;
}

.keyFeatures.Orangeh6 h6{
  margin-top: 0;
}

.keyFeatures.Orangeh6 p{
  margin-bottom: 0;
}














@media (min-width: 974px) and (max-width: 1198.98px){
  .navbar-nav li a{margin:0 10px;font-size:16px;}
  .navbar-brand img{width:100%;}
}

@media (min-width: 1200px) and (max-width: 1650px){
  .navbar-nav li a{margin:0 10px;font-size:16px;}
}

@media (max-width: 320px){
  body .InnerHeader{height:200px !important;}
}

.postBox .postThumb{
  width: 476px;
  height: 317px;
  display: block;
  overflow: hidden;
}

.postDate{
  position: absolute;
  bottom: 0;
  /*background: rgba(236, 103, 7, 0.7);*/
  /*background: rgba(0,0,0,0.4);*/
  padding: 5px 15px;
  right: 0;
}

.postDate span{
  font-family: bsl;
  font-size: 14px;
  color: #fff;
  text-shadow: 0px 0px 5px rgb(0 0 0);
}

.postDateInner span{
  font-family: bsl;
  font-size: 14px;
  /* color: rgba(36, 36, 36, 0.7); */
  color: rgba(39, 39, 39, 0.7);
}

.customHR{
  color: rgba(0,0,0,0.3);
}

.pagedescInnerHeader{
  font-family: bsl;
  font-size: 16px;
  /* color: rgba(36, 36, 36, 0.8); */
  color: #272727;
  margin-bottom: 30px;
}

.pagedescInnerHeaderMargin{
  margin-left: 60px;
}

.accordion .accordion-item .accordion-button{
  font-family: bsl;
  font-size: 16.67px;
  color: #575757;
  background: transparent;
  border: none;
  box-shadow: none;
  border-bottom: 1px solid #dedede;
}

.accordion .accordion-item{
  border: none;
  background-color: transparent;
}

.accordion .accordion-item .accordion-body{
  color:#575757;
  font-size: 14px;
  padding-bottom: 30px;
  font-family: bsl;
}

.FAQsHomePadding{padding:80px 0 120px 0;}

body{
  overflow-x: hidden;
}

.copyright span{
  color:hsla(0,0%,100%,.5);
}

.FAQsHome .NaN, .accordion-collapse.show{border-bottom:1px solid #ec6707 !important;}

.accordion-item:first-of-type .accordion-button{border-bottom:none;padding-bottom:5px;}

.accordion-item .accordion-button[aria-expanded="true"]{border-bottom:none;padding-bottom:5px;}

.accordion .accordion-item .accordion-button[aria-expanded="false"]::after{
  background: url(./images/plus.png) no-repeat !important;
}

.accordion .accordion-item .accordion-button[aria-expanded="true"]::after{
  background: url(./images/minus.png) no-repeat;
  border-bottom:none;padding-bottom:5px;position: relative;top:-8px;
}

.accordion .accordion-item:first-of-type .accordion-button::after{
  background: url(./images/minus.png) no-repeat !important;
  border-bottom:none;padding-bottom:5px;position: relative;top:-8px;
}

body .Hero .slick-list .slick-track .slick-slide .heroText{
  position: absolute;
  bottom: 60px;
  left: 0;
  right: 0;
  height: auto !important;
  text-align: center;
}

.heroText h2{
  color: #FFF;
  font-size: 36px;
}

.heroText h1{
  color: #FFF;
  font-size: 36px;
}


.heroText p{
  color: #FFF;
  font-size: 20px;
}

.wardrobeHome{padding:140px 0 80px 0;}

.middleContent{
  padding: 180px 0;
}

div#LogosHome{
  padding: 30px 0 100px 0;
}

.ImgbgRight:after{
  left: inherit;
  right: -60px;
  top: inherit;
  bottom: -60px;
}

/* .kitchenSliderHome .slick-dots li button {
  width: 60px;
  height: 10px;
}

.kitchenSliderHome .slick-dots li button:before {width: 60px;height: 6px;font-size: 0 !important;opacity: 1 !important;}

.kitchenSliderHome .slick-dots li {
  width: 60px;
  height: 10px;
}

.SliderHome .kitchenSliderHome .slick-dots{
  bottom: 100px;
} */

.KeyFeaturesWardrobe{
  padding: 140px 0;
  background: #F7F7F7;
}

.topColContent{
  padding: 100px 0;
}

.valueBox{
  position: relative;
  padding-left: 28px;
  padding-right: 120px;
}

.valueBox:before{
  position: absolute;
  content: "";
  background: url(images/value-tick.svg) no-repeat;
  background-size: 100%;
  height: 14px;
  width: 12px;
  top: 3px;
  left: 0;
}

.valueBox h3{
  color: #272727;
  font-family: 'bsl';
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  text-transform: uppercase;
}

.valueBox p{
  color: #272727;
  font-family: 'bsl';
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
}

.mbValue .col-md-4{
  margin-bottom: 20px;
}

.bottomColContent{
  padding: 120px 0;
}

.textModule1Gray p{
  color: #272727;
}

.bigUl ul{
  color: #272727;
  font-family: 'bsl';
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 160%; /* 38.4px */
  list-style: none;
  padding-left: 0;
}

.bigUl ul li{
  position: relative;
  padding-left: 40px;
  margin-bottom: 4px;
}

.bigUl ul li:before{
  position: absolute;
  content: "";
  background: url(images/value-tick.svg) no-repeat;
  background-size: 100%;
  height: 21px;
  width: 18px;
  top: 10px;
  left: 0;
}

.SmallInnerHeader h1{
  font-size: 40px;
}

.sectionPadding{
  padding: 140px 0;
}

.collectionBox{
  padding: 100px 0;
}

.collectionBox .textModule1 h3{
  margin-bottom: 20px;
}

.KitchenProBox p{
  font-family: "bsl";
  font-size: 16px;
  font-weight: 300;
  color: #272727;
  /* color: rgba(36, 36, 36, 0.8); */
}

.collectionProBox h3{
  margin:30px 0 10px 0;
}

.PortfolioKd{
  padding: 100px 0 0 0;
}

.graykey{
  position: relative;
  color: #272727;
  font-family: 'bsl';
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 120%; /* 24px */
  padding-left: 30px;
  margin-bottom: 30px;
  display: inline-block;
}

.graykey:before{
  position: absolute;
  content: "";
  background: #E6E6E6;
  width:3px;
  height: 100%;
  top: 0;
  left: 0;
}

.PortfolioHome.PortfolioKdSec{
  padding: 120px 0 0 0;
}

.PortfolioRowHome .col-12{
  margin-bottom: 50px;
}
.PortfolioRowHome .col-12:nth-last-child(1){
  margin-bottom: 0;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .graykey{padding:0 0 0 15px !important;}
  .sectionPadding{padding:40px 0;}
  .IntroTwoColumns.sectionPadding{padding-top:0;padding-bottom:60px;}
  .rowMb2 .col-sm-4{margin-bottom:50px;}
  .rowMb2 .col-sm-4:nth-last-child(1){margin-bottom:0;}
  .collectionBox.SystemCollection{padding:60px 0 0 0;}
  .portfolioKd .textModule1 h3 br{display:none;}
  .collectionBox{padding:40px 0;}

  .topColContent{padding:40px 0;}
  .valueBox{padding-right:0;}
  .bottomColContent{padding: 40px 0;}
  .KeyFeaturesWardrobe{padding:40px 0;}
  .textModule1 h3{font-size:24px;}
  .bigUl ul{font-size:16px;}
  .bigUl ul li{padding-left:25px;}
  .bigUl ul li:before{width:12px;height:12px;top:6px;}
  .LogosHomeSlider .slick-dots{position: relative;margin: 0 auto 30px auto;overflow:hidden;white-space:nowrap;width:50%;}
  .postTitle{min-height:inherit;}
  .paginationNew li.break{display:none;}
  .paginationNew li:nth-last-child(2), .paginationNew li:nth-last-child(3), .paginationNew li:nth-last-child(4), .paginationNew li:nth-last-child(5){display:none;}
  .paginationNew li a{padding:4px 10px;font-size:14px;}
  .InsightsHome .row .col-6 .imgMagnify img{width:100%;}
  .About .InnerHeaderParent, .WhyChoose .InnerHeaderParent, .Quality .InnerHeaderParent, .BenefitsForYou .InnerHeaderParent, .Blogs .InnerHeaderPlain, .Kitchens .Hero, .KitchenDetail .InnerHeaderParent{
    padding-top: 30px;
  }
  .site-header{height:64px;}
  .textModule2 a, .textModule2 p{font-size:14px;}
  .PaddingTopHeding{padding-top:64px !important;}
  .VideoBanner{height:240px;}
  .Filters h5{font-size:16px;}
  .Hero{margin-top:40px !important;}
  .WardrobeBoxModal .close{top:10px;right:8px;}
  .WardrobeBoxModal .close svg{width:14px;height:14px;}
  .WardrobeBoxModal .close svg path{fill:rgba(0,0,0,0.3);}
  .PortfolioRowHome .col-xl-5:nth-child(2) .PortfolioBox h3{margin-left:0;}
  .twoColImage a{height:300px;}
  .KitchenDetail .thumbBox, .portfolioDetail .thumbBox{height:450px;}
  ._wPDyp{
    flex: 0 0 46%
  }
  .circleAttr {
    width: 22px;
    height: 22px;
  }
  .portfolioAttr {
    display: block;
  }
  .portfolioAttr span:nth-child(1) {
    margin-bottom: 15px;
  }
  .logoImg {
    width: 120px !important;
  }
  .p-25 {
    padding: 0 25px;
  }
  .FullWidthBtn a {
    font-size: 16px;
  }
  .FullWidthBtnIcon {
    width: 18px;
    height: 18px;
    margin-right: 20px;
  }
  .LeftRightBoxesMargin {
    margin-top: 40px;
    padding-top: 40px;
  }
  .KitchenProBox_TextBoxMargin {
    margin-bottom: 20px;
    padding-bottom: 20px;
  }
  .LeftRightBoxes .row:nth-child(even) .col-6:nth-child(1) {
    order: 1;
  }
  .postHeaderMargin {
    margin: 40px 0 0px 0;
  }
  .postContentTitle {
    padding: 0 20px 0 0px;
    font-size: 22px;
  }
  .postContent .size-full {
    margin-bottom: 50px;
    margin-top: 40px;
    width: 100%;
    height: 100%;
  }
  .postContent p,
  .postContent ul,
  .postContent ol,
  .postContent p span {
    padding: 0;
  }
  .btnBorderBig {
    padding: 20px 20px 18px 20px;
    font-size: 12px;
  }
  .postBigBtn {
    padding: 40px 0 60px 0;
  }
  .WhatsappIcon {
    right: 30px;
    bottom: 20px;
    width: 42px;
    display: block;
  }
  .fulwidth_TabsMargin {
    margin: 40px 0 80px 0;
  }
  .fulwidth_Tabs .nav-tabs .nav-link {
    font-size: 14px;
  }
  .fulwidth_Tabs .nav-item.col {
    flex: 0 0 100%;
  }
  .fulwidth_Tabs .nav-tabs .nav-link {
    padding: 12px 40px;
  }
  .container {
    padding: 0 25px !important;
  }
  .VideoThumb .playIcon {
    width: 80px;
    height: 80px;
  }
  .HistorySliderMargin {
    padding: 60px 0 60px 35px;
  }
  .Imgbg {
    text-align: right;
  }
  .Imgbg:after {
    width: 86%;
    top: -35px;
    left: 0;
  }
  .Imgbg img {
    width: 90%;
  }
  .TextModuleLeftImage1Margin {
    padding: 100px 0 40px 0;
  }
  .ThreeBlocks span {
    padding-left: 20px !important;
    margin-bottom: 20px;
    margin-top: 0;
  }
  .ParallaxSmallBanner {
    background-attachment: inherit !important;
    background-size: cover !important;
  }
  .TextModuleRightImage1Margin {
    padding: 60px 0;
  }
  .LogoAndText img {
    width: auto !important;
  }
  .TextModuleCenterFourBlocksMargin {
    padding: 20px 0;
  }
  .FourBlocksImg .col {
    flex: 0 0 50%;
    margin-right: 0;
  }
  .FourBlocksImg span {
    margin-left: -20px;
    margin-top: 10px !important;
  }
  .TextModuleLeftImage2Margin {
    padding: 60px 0 0 0;
  }
  .ShowroomSlider img {
    width: 100%;
  }
  .textModule1 h2 {
    font-size: 18px;
  }
  .textModule1 h1 {
    font-size: 18px;
  }
  .textModule1 p {
    font-size: 14px;
  }
  .textModule1 p br {
    display: none;
  }
  .IntroText {
    padding: 80px 40px 60px 40px;
  }
  .textModule1 h2:nth-last-child(4) {
    margin-bottom: 20px;
  }
  .textModule1 span {
    font-size: 14px;
    padding: 0 5px;
  }
  .absoluteHeadingLeft {
    position: relative;
    top: 0;
  }
  .absoluteHeadingLeft h2 {
    text-align: center;
    font-size: 30px;
    margin-bottom: 30px;
  }
  .PortfolioBox {
    text-align: center !important;
    padding-bottom: 30px;
  }
  .PortfolioBox h3 {
    padding-top: 30px;
  }
  .PortfolioBox h3 a {
    margin-top: 0;
    font-size: 18px;
    margin-right: 0;
  }
  body .PortfolioHome .PortfolioRowHome .col-xl-5 .PortfolioBox .PortfolioBoxImgSmall{
    background-size: cover;
    background-position: center;
  }
  .PortfolioBox .PortfolioBoxImgBig {
    background-size: cover;
    height: 500px;
  }
  .marginBtn {
    margin: 20px 0 60px 0;
  }
  .absoluteHeadingRight {
    position: relative;
    top: 0;
    right: 0;
  }
  .absoluteHeadingRight h2 {
    text-align: center;
    font-size: 26px !important;
    margin-bottom: 30px;
  }

  .SliderHome img {
    width: 100%;
  }
  .textModule2HomeMargin {
    margin: 50px 40px;
  }
  .LogosHome h2 {
    text-align: center;
    margin-bottom: 35px !important;
  }
  .SliderHome .slick-dots {
    bottom: 30px;
  }
  .SliderWardrobe h2 {
    font-size: 20px;
  }
  .custom-slickdots .slick-dots li button:before {
    font-size: 7px;
  }
  .SliderHomeWardrobeMargin {
    margin: 40px 0;
  }
  .TestimonialHomeMargin {
    margin-top: 70px;
  }
  .TestimonialHome h2 {
    font-size: 16px;
    padding: 0 30px;
  }
  .TestimonialHome h2:before {
    transform: translate(-40%, 0%);
    background-size: 70px;
    top: -40px;
  }
  .TestimonialHome span {
    font-size: 14px;
  }
  .InsightsHome .textModule2 {
    text-align: center;
  }
  .InsightsHome .textModule2 p {
    margin: 0 50px 30px 50px;
  }
  .fsz25 {
    font-size: 22px !important;
  }
  .InsightsHomemobileMargin {
    margin-bottom: 40px;
  }
  .InsightsHome .col-xl-4 img {
    width: 100%;
  }
  .HomeCTA h2 {
    font-size: 22px;
  }
  .HomeCTA p {
    font-size: 16px;
  }
  .HomeCTA p br {
    display: none;
  }
  .HomeCTA_BG {
    padding: 60px 20px;
    margin: 50px 0 0 0;
  }
  .InstaHome img {
    width: 100%;
    margin-bottom: 20px;
  }
  .InstaHomeMargin h2 {
    margin-bottom: 30px;
  }
  .InstaHomeMargin .col-xl-3:nth-last-child(1) img,
  .InstaHomeMargin .col-xl-3:nth-last-child(2) img {
    margin-bottom: 0;
  }
  .InstaHome {
    margin-bottom: 25px;
  }
  .footer {
    padding: 60px 0;
  }
  .Footer_Links ul {
    display: flex;
    flex-wrap: wrap;
  }
  .Footer_Links li {
    flex: 0 0 50%;
    text-align: center;
  }
  .footerAddress {
    text-align: center;
  }
  .FooterNewsletter {
    padding: 0 50px;
    text-align: center;
    margin: 50px 0;
  }
  .footer h3 {
    font-size: 16px;
  }
  .footerAddress {
    text-align: center;
    padding: 0 70px !important;
  }
  .copyright {
    font-size: 14px;
  }
  .footer input[type="email"] {
    width: 75%;
  }
  .footer input[type="submit"] {
    margin-top: 20px;
  }
  .Home .container {
    padding: 0 25px !important;
  }
  /* About page css */
  .InnerHeader {
    height: 240px !important;
  }
  .InnerHeaderText h1 {
    margin-left: 0;
    text-align: left;
    font-size: 40px;
    margin: 40px 0 0 20px;
  }
  .AboutIntroText {
    padding: 20px 0 40px 0;
  }
  .blockQuote {
    text-align: center;
  }
  .blockQuote blockquote {
    font-size: 20px;
    padding: 0 20px;
  }
  .blockQuote blockquote br {
    display: none;
  }
  .Showroom {
    padding: 80px 0 40px 0;
  }
  .textModule1 h2:nth-last-child(4),
  .Showroom h2:nth-last-child(2) {
    margin-bottom: 20px;
  }
  .mobileWidth60 {
    max-width: 60%;
    margin: auto;
  }
  .custom_ul1 ul {
    font-size: 14px;
    padding-left: 20px;
  }
  .custom_ul1 ul li:before {
    left: -20px;
    background-size: 50%;
    top: 6px;
  }
  .textModule3Margin {
    padding-bottom: 60px;
  }
  .teamBox img {
    width: 100%;
  }
  .teamTextBoxParent {
    position: relative;
    left: 20px;
    width: 92%;
    margin-bottom: 20px;
  }
  .teamTextBox h3 {
    font-size: 32px;
  }
  .customArrowsBlack .slick-next {
    top: 50px;
  }
  .teamCarousel .slick-track {
    margin-bottom: 0;
  }
  .Team {
    padding: 0 0 30px 0;
  }
  .LogosAbout {
    margin-bottom: 50px;
  }
  .SliderText2 h2 {
    font-size: 24px;
  }
  .SliderText2 span {
    font-size: 14px;
    margin-bottom: 0;
  }
  .WhyChooseSlider .slick-dots {
    bottom: 15px;
  }
  .customArrowsWhite .slick-next {
    width: 16px;
    height: 31px;
    background-size: 16px;
    z-index: 99;
  }
  .NextImage {
    margin: 40px 0;
  }
  .LogosAbout .slick-next,
  .LogosAbout .slick-next {
    display: none !important;
  }
  .NavFixed {
    display: none;
  }
  .QualityIntroMargin {
    padding: 40px 0;
  }
  .QualityNav img {
    width: 100%;
  }
  .QualityNav ul li a {
    font-size: 14px;
    padding: 6px 10px;
  }
  .QualityNav ul li:nth-child(1) {
    left: 0;
  }
  .QualitySection .VideoThumb a {
    height: 227.23px;
  }
  .QualitySection .VideoThumb a img {
    opacity: 0;
  }
  .QualityThreeCols .row .col-xl-4 {
    margin-bottom: 35px;
  }
  .QualityThreeCols .row .col-xl-4:nth-last-child(1) {
    margin-bottom: 0;
  }
  .QualityThreeColsText h3 {
    margin: 20px 0 10px 0;
  }
  .QualitySection .VideoThumb {
    margin-bottom: 3rem;
  }
  .QualitySection.Orangeh6 h6 {
    margin: 10px 0;
  }
  .QualityNav ul li:after {
    width: 8px;
    height: 8px;
  }
  .QualityNav li {
    position: relative;
    left: inherit !important;
    right: inherit !important;
    top: inherit !important;
    bottom: inherit !important;
    transform: translate(0) !important;
  }
  .QualityNav ul li:before{
    display: none;
  }
  .QualityNav ul li:nth-child(1):after {top: 200px; left: 50px;}
  .QualityNav ul li:nth-child(2):after { top: 210px; left: 75px; }
  .QualityNav ul li:nth-child(3):after { top: 150px; left: 120px; }
  .QualityNav ul li:nth-child(4):after { top: 200px; left: 150px; }
  .QualityNav ul li:nth-child(5):after { top: 150px; left: 300px; }
  .QualityNav ul li:nth-child(3) { right: 7%; top: 15px; }
  .QualityNav ul li:nth-child(5) { bottom: -10px; left: 40%; }
  .BenefitsMargin {
    margin-top: 30px;
  }
  .HalfWidthImage_textBox {
    padding: 40px 25px;
  }
  .ImageHeader {
    height: 250px !important;
  }
  .contact_form {
    padding: 35px 30px;
  }
  .contact_form h2 {
    font-size: 22px;
  }
  .ContactInfoForm {
    padding: 60px 0;
  }
  .intro_text_contact {
    margin-bottom: 30px;
    padding-bottom: 30px;
  }
  .SocialMediaIconsMargin {
    padding: 60px 0;
  }
  .contact_form input[type="submit"] {
    font-size: 14px;
    padding: 12px 0;
  }
  .SliderHome .portfolioSlider a{
    height: 300px;
  }
  .mapCode iframe{height:350px;}
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  .graykey{padding:0 0 0 15px !important;}
  .sectionPadding{padding:80px 0;}
  .topColContent{padding:80px 0;}
  /* .IntroTwoColumns.sectionPadding{padding-top:0;padding-bottom:60px;} */
  .rowMb2 .col-md-4{margin-bottom:50px;}
  .rowMb2 .col-md-4:nth-last-child(1){margin-bottom:0;}
  .collectionBox.SystemCollection{padding:80px 0 80px 0;}
  .PortfolioKdSec .textModule1 h3 br{display:none;}
  body .PortfolioHome .PortfolioRowHome .col-xl-5 .PortfolioBox .PortfolioBoxImgSmall{background-size:cover!important;}
  .portfolioKd .textModule1 h3 br{display:none;}
  .collectionBox{padding:40px 0;}
  .WardrobeBox .textModule1 h2{font-size:18px;}

  .WardrobeList .InnerHeader{background-position:right -210px center;}
  .Orangeh6 h6 br{display:none;}
  .bottomColContent.bottomColContent2{padding-top:0;}
  .WardrobesAfter .wardrobeHome{padding-top:60px;}
  .bigUl ul{font-size:20px;}
  .KeyFeaturesWardrobe{padding:100px 0;}


  .postBox .postThumb{width:100%;}
  .paginationNew li a{font-size:14px;padding:4px 10px;}
  .mapCode iframe{height:350px;}
  .textModule2 a, .textModule2 p{font-size:14px;}
  .btn1{font-size: 14px;}
  .VideoBanner{height:330px;}
  .Hero{margin-top:40px !important;}
  .twoColImage a{height:200px;}
  .KitchenDetail .thumbBox, .portfolioDetail .thumbBox{height:400px;}
  ._wPDyp{
    flex: 0 0 46%
  }
  .circleAttr {
    width: 22px;
    height: 22px;
  }
  .FullWidthBtn a {
    font-size: 16px;
  }
  .FullWidthBtnIcon {
    width: 18px;
    height: 18px;
    margin-right: 20px;
  }
  .logoImg {
    width: 120px !important;
  }
  .LeftRightBoxesMargin {
    margin-top: 40px;
    padding-top: 40px;
  }
  .KitchenProBox_TextBoxMargin {
    margin-bottom: 20px;
    padding-bottom: 20px;
  }
  .KitchenProBox.Orangeh6 h6 {
    margin-bottom: 15px;
  }
  .KPbgImg {
    height: 560px !important;
  }
  .BenefitsMargin {
    margin-top: 60px;
  }
  .postHeaderMargin {
    margin: 40px 0 0px 0;
  }
  .postContentTitle {
    padding: 0 20px 0 20px;
    font-size: 24px;
  }
  .postContent .size-full {
    margin-bottom: 50px;
    margin-top: 40px;
    width: 100%;
    height: 100%;
  }
  .postContent p,
  .postContent ul,
  .postContent ol,
  .postContent p span {
    padding: 0;
  }
  .btnBorderBig {
    padding: 20px 20px 18px 20px;
    font-size: 12px;
  }
  .postBigBtn {
    padding: 40px 0 60px 0;
  }
  .btnBorderMargin .btnBorder {
    margin-top: 10px;
  }
  .fulwidth_TabsMargin {
    margin: 40px 0 80px 0;
  }
  .fulwidth_Tabs .nav-tabs .nav-link {
    font-size: 11px;
  }
  .fulwidth_Tabs .nav-tabs .nav-link {
    padding: 12px 40px;
  }
  .textModule1 h2 {
    font-size: 32px;
  }
  .textModule1 p {
    font-size: 14px;
  }
  .textModule1 p br {
    display: none;
  }
  .IntroText {
    padding: 80px 40px 60px 40px;
  }
  .textModule1 h2:nth-last-child(4) {
    margin-bottom: 20px;
  }
  .textModule1 span {
    font-size: 14px;
    padding: 0 5px;
  }
  .absoluteHeadingLeft {
    position: relative;
    top: 0;
  }
  .absoluteHeadingLeft h2 {
    text-align: center;
    font-size: 30px;
    margin-bottom: 30px;
  }
  .PortfolioBox {
    text-align: center !important;
    padding-bottom: 30px;
  }
  .PortfolioBox h3 {
    padding-top: 20px;
  }
  .PortfolioBox h3 a {
    margin-top: 0;
    font-size: 16px;
    margin-right: 0;
  }
  .textModule2 h2{font-size:20px;}
  .PortfolioBox .PortfolioBoxImgSmall {
    background-size: cover;
    background-position: center;
  }
  .PortfolioBox .PortfolioBoxImgBig {
    background-size: cover;
    height: 500px;
  }
  .marginBtn {
    margin: 20px 0 60px 0;
  }
  .absoluteHeadingRight {
    position: relative;
    top: 0;
    right: 0;
  }
  .absoluteHeadingRight h2 {
    text-align: center;
    font-size: 26px !important;
    margin-bottom: 30px;
  }
  .SliderHome img {
    width: 100%;
  }
  .textModule2HomeMargin {
    margin: 50px 40px;
  }
  .LogosHome h2 {
    text-align: center;
    margin-bottom: 35px !important;
  }
  .SliderHome .slick-dots {
    bottom: 30px;
  }
  .SliderWardrobe h2 {
    font-size: 20px;
  }
  .custom-slickdots .slick-dots li button:before {
    font-size: 7px;
  }
  .SliderHomeWardrobeMargin {
    margin: 40px 0;
  }
  .TestimonialHomeMargin {
    margin-top: 110px;
  }
  .TestimonialHome h2 {
    font-size: 20px;
    padding: 0 30px;
  }
  .TestimonialHome h2:before {
    transform: translate(-40%, 0%);
    background-size: 70px;
    top: -40px;
  }
  .TestimonialHome span {
    font-size: 14px;
  }
  .InsightsHome .textModule2 {
    text-align: center;
  }
  .InsightsHome .textModule2 p {
    margin: 0 50px 30px 50px;
  }
  .fsz25 {
    font-size: 22px !important;
  }
  .InsightsHomemobileMargin {
    margin-bottom: 40px;
  }
  .InsightsHome .col-xl-4 img {
    width: 100%;
  }
  .HomeCTA h2 {
    font-size: 18px;
  }
  .HomeCTA p {
    font-size: 16px;
  }
  .HomeCTA p br {
    display: none;
  }
  .HomeCTA_BG {
    padding: 60px 20px;
    margin: 50px 0 0 0;
  }
  .InstaHome img {
    width: 100%;
    margin-bottom: 20px;
  }
  .InstaHomeMargin h2 {
    margin-bottom: 30px;
  }
  .InstaHomeMargin .col-xl-3:nth-last-child(1) img,
  .InstaHomeMargin .col-xl-3:nth-last-child(2) img {
    margin-bottom: 0;
  }
  .InstaHome {
    margin-bottom: 25px;
  }
  .footer {
    padding: 60px 0;
  }
  .Footer_Links ul {
    display: flex;
    flex-wrap: wrap;
  }
  .Footer_Links li {
    flex: 0 0 50%;
    text-align: center;
  }
  .footerAddress {
    text-align: center;
  }
  .FooterNewsletter {
    padding: 0 50px;
    text-align: center;
    margin: 50px 0;
  }
  .footer h3 {
    font-size: 16px;
  }
  .footerAddress {
    text-align: center;
    padding: 0 70px !important;
  }
  .copyright {
    font-size: 14px;
  }
  .footer input[type="email"] {
    width: 75%;
  }
  .footer input[type="submit"] {
    margin-top: 20px;
  }
  .Home .container {
    padding: 0 25px !important;
  }
  /* About page css */
  .InnerHeader {
    height: 280px !important;
  }
  .InnerHeaderText h1 {
    margin-left: 0;
    text-align: left;
    font-size: 40px;
    margin: 40px 0 0 20px;
  }
  .pagedescInnerHeaderMargin{margin-left:20px;}
  .AboutIntroText {
    padding: 20px 0 40px 0;
  }
  .blockQuote {
    text-align: left;
  }
  .blockQuote blockquote {
    font-size: 16px;
    padding: 0 20px 0 0;
  }
  .blockQuote blockquote br {
    display: none;
  }
  .blockQuote span .Showroom {
    padding: 80px 0 40px 0;
  }
  .textModule1 h2:nth-last-child(4),
  .Showroom h2:nth-last-child(2) {
    margin-bottom: 20px;
  }
  .mobileWidth60 {
    max-width: 60%;
    margin: auto;
  }
  .custom_ul1 ul {
    font-size: 14px;
    padding-left: 20px;
  }
  .custom_ul1 ul li:before {
    left: -20px;
    background-size: 50%;
    top: 6px;
  }
  .textModule3Margin {
    padding-bottom: 60px;
  }
  .teamBox img {
    width: 100%;
  }
  .teamTextBoxParent {
    position: relative;
    left: 20px;
    width: 92%;
    margin-bottom: 20px;
  }
  .teamTextBox h3 {
    font-size: 32px;
  }
  .teamCarousel .slick-track {
    margin-bottom: 0;
  }
  .Team {
    padding: 0 0 30px 0;
  }
  .LogosAbout {
    margin-bottom: 50px;
  }
  .SliderText2 h2 {
    font-size: 24px;
  }
  .SliderText2 span {
    font-size: 16px;
  }
  .WhyChooseSlider .slick-dots {
    bottom: 15px;
  }
  .customArrowsWhite .slick-next {
    width: 16px;
    height: 31px;
    background-size: 16px;
    z-index: 99;
  }
  .NextImage {
    margin: 40px 0;
  }
  .LogosAbout .slick-next,
  .LogosAbout .slick-next {
    display: none !important;
  }
  .NavFixed {
    display: none;
  }
  .AboutIntroText {
    padding: 60px 0;
  }
  .blockQuote span {
    margin-top: 30px;
    font-size: 14px;
  }
  .mobileWidth60 {
    max-width: 90%;
  }
  .ShowroomSlider img {
    width: 80%;
  }
  .Showroom {
    padding: 60px 0 80px 0;
  }
  .VideoThumb .playIcon {
    width: 80px;
    height: 80px;
  }
  .HistorySliderMargin {
    padding: 60px 0 60px 35px;
  }
  .Imgbg {
    text-align: right;
  }
  .Imgbg:after {
    width: 86%;
    top: -35px;
    left: 0;
  }
  .Imgbg img {
    width: 90%;
  }
  .TextModuleLeftImage1Margin {
    padding: 100px 0 40px 0;
  }
  .ThreeBlocks span {
    padding-left: 20px !important;
    margin-bottom: 20px;
    margin-top: 0;
  }
  .ParallaxSmallBanner {
    background-attachment: inherit !important;
    background-size: cover !important;
  }
  .TextModuleRightImage1Margin {
    padding: 60px 0;
  }
  .LogoAndText img {
    width: auto !important;
  }
  .TextModuleCenterFourBlocksMargin {
    padding: 20px 0;
  }
  .FourBlocksImg .col {
    flex: 0 0 50%;
    margin-right: 0;
  }
  .FourBlocksImg span {
    margin-left: -20px;
    margin-top: 10px !important;
  }
  .TextModuleLeftImage2Margin {
    padding: 60px 0 0 0;
  }
  .QualityIntroMargin {
    padding: 40px 0;
  }
  .QualityNav img {
    width: 100%;
  }
  .QualityNav ul li a {
    font-size: 14px;
    padding: 6px 10px;
  }
  .QualityNav ul li:nth-child(1) {
    left: 0;
  }
  .QualitySection .VideoThumb a {
    height: 312px;
  }
  .QualitySection .VideoThumb a img {
    opacity: 0;
  }
  .QualityThreeCols .row .col-xl-4 {
    margin-bottom: 35px;
  }
  .QualityThreeCols .row .col-xl-4:nth-last-child(1) {
    margin-bottom: 0;
  }
  .QualityThreeColsText h3 {
    margin: 20px 0 10px 0;
  }
  .QualitySection .VideoThumb {
    margin-bottom: 3rem;
  }
  .QualitySection.Orangeh6 h6 {
    margin: 10px 0;
  }
  .QualityNav ul li:after {
    width: 8px;
    height: 8px;
  }
  .QualityNav ul li:nth-child(1):before {
    top: 34px;
    left: 36px;
    height: 50px;
  }
  .QualityNav ul li:nth-child(1):after {
    top: 80px;
    left: 33px;
  }
  .QualityNav ul li:nth-child(2):before {
    top: 25px;
    left: 66px;
    height: 52px;
  }
  .QualityNav ul li:nth-child(2):after {
    top: 72px;
    left: 62px;
  }
  .QualityNav ul li:nth-child(3) {
    right: 7%;
    top: 15px;
  }
  .QualityNav ul li:nth-child(3):before {
    top: 25px;
    left: 18px;
    height: 80px;
  }
  .QualityNav ul li:nth-child(3):after {
    top: 100px;
    left: 15px;
  }
  .QualityNav ul li:nth-child(4):before {
    top: -110px;
    left: 34px;
    height: 108px;
  }
  .QualityNav ul li:nth-child(4):after {
    top: -110px;
    left: 30px;
  }
  .QualityNav ul li:nth-child(5) {
    bottom: -10px;
    left: 40%;
  }
  .QualityNav ul li:nth-child(5):before {
    top: -46px;
    left: 20px;
    height: 45px;
  }
  .QualityNav ul li:nth-child(5):after {
    top: -50px;
    left: 17px;
  }
  .PortfolioRowHome .col-xl-5:nth-child(2) .PortfolioBox h3,
  .PortfolioRowHome .col-xl-5:nth-child(2) .PortfolioBox span {
    margin-left: 0;
  }
  .ImageHeader {
    height: 250px !important;
  }
  .contact_form {
    padding: 35px 30px;
  }
  .contact_form h2 {
    font-size: 22px;
  }
  .ContactInfoForm {
    padding: 60px 0;
  }
  .intro_text_contact {
    margin-bottom: 30px;
    padding-bottom: 30px;
  }
  .SocialMediaIconsMargin {
    padding: 60px 0;
  }
  .contact_form input[type="submit"] {
    font-size: 14px;
    padding: 12px 0;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  .graykey{padding:0 0 0 15px !important;}
  .sectionPadding{padding:80px 0;}
  .topColContent{padding:80px 0;}
  /* .IntroTwoColumns.sectionPadding{padding-top:0;padding-bottom:60px;} */
  .rowMb2 .col-md-4{margin-bottom:50px;}
  .rowMb2 .col-md-4:nth-last-child(1){margin-bottom:0;}
  .collectionBox.SystemCollection{padding:80px 0 80px 0;}
  .PortfolioKdSec .textModule1 h3 br{display:none;}
  body .PortfolioHome .PortfolioRowHome .col-xl-5 .PortfolioBox .PortfolioBoxImgSmall{background-size:cover!important;}
  .portfolioKd .textModule1 h3 br{display:none;}
  .collectionBox{padding:40px 0;}

  body .WardrobeList .WardrobesIntro .InnerHeaderParent .InnerHeader{background-position:right -210px center;}
  .Orangeh6 h6 br{display:none;}
  .bottomColContent.bottomColContent2{padding-top:0;}
  .WardrobesAfter .wardrobeHome{padding-top:60px;}
  .bigUl ul{font-size:20px;}
  .KeyFeaturesWardrobe{padding:100px 0;}
  .valueBox{padding-right:0;}
  .KeyFeaturesWardrobe.textModule1 .bigUl h3{font-size:22px;}
  .bigUl ul{font-size:16px;}
  .bigUl ul li{padding-left:30px;}
  .bigUl ul li:before{height:13px;width:13px;top:7px;}


  .postTitle{min-height: inherit;}
  .LogosHomeSlider .slick-dots{bottom:-45px}
  .LogosHome h2{margin-bottom:25px !important;text-align:center;}
  .site-header{padding:20px 0;}
  .VideoBanner{height:550px;}
  .Filters h5{font-size:16px;}
  .twoColImage a{height:300px;}
  .KitchenDetail .thumbBox, .portfolioDetail .thumbBox{height:350px;}
  .KitchenProBox h3{font-size:18px;}
  .navbar-brand img{width:100% !important;}
  .QualityNav ul li a{font-size: 14px;}
  .About .InnerHeaderParent, .WhyChoose .InnerHeaderParent, .Quality .InnerHeaderParent, .BenefitsForYou .InnerHeaderParent, .Blogs .InnerHeaderPlain, .Kitchens .Hero, .KitchenDetail .InnerHeaderParent{padding-top:39px;}
  .PaddingTopHeding{padding-top:83px;}
  ._2R-kh{
    justify-content: center;
  }
  ._wPDyp{
    flex: 0 0 23%
  }
  .circleAttr {
    width: 22px;
    height: 22px;
  }
  .portfolioAttr {
    display: block;
  }
  .portfolioAttr span:nth-child(1) {
    margin-bottom: 15px;
  }
  .VideoThumb .playIcon {
    width: 60px;
    height: 60px;
  }
  .navbar-brand img {
    width: 100%;
  }
  /* .PaddingTopHeding{padding-top:70px;} */
  .pad-md-key {
    padding-left: 40px;
  }
  .squareDotsMargin .slick-dots {
    margin-bottom: 0;
  }
  .kitchenCta__text p {
    font-size: 16px;
  }
  .FullWidthBtn a {
    font-size: 18px;
  }
  .enquireBtn {
    padding: 7px 25px;
    font-size: 12px;
  }
  .underlineBtn {
    font-size: 12px;
  }
  .logoImg {
    width: 120px !important;
  }
  .LeftRightBoxesMargin {
    margin-top: 40px;
    padding-top: 40px;
  }
  .KitchenProBox_TextBoxMargin {
    margin-bottom: 20px;
    padding-bottom: 20px;
  }
  .KitchenProBox.Orangeh6 h6 {
    margin-bottom: 15px;
  }
  .KPbgImg {
    height: 560px !important;
  }
  .BenefitsMargin {
    margin-top: 60px;
  }
  .BenefitsBlocks img {
    height: 100%;
    object-fit: cover;
  }
  .ThreeBlocks span br {
    display: none;
  }
  .imgHeightBenefits {
    height: 600px;
  }
  .navbar-nav li a {
    font-size: 13px;
    padding: 0 0;
    margin: 0 4px;
  }
  .Submenu a {
    font-size: 14px;
  }
  .textModule1 p br {
    display: none;
  }
  .PortfolioBox .PortfolioBoxImgBig {
    height: 450px;
  }
  .PortfolioBox .PortfolioBoxImgSmall {
    height: 350px;
  }
  .absoluteHeadingLeft {
    left: 0;
    top: 0;
  }
  .absoluteHeadingLeft h2 {
    font-size: 40px;
  }
  .PortfolioBox h3 a {
    font-size: 16px;
    margin-top: 30px;
  }
  .SliderHome img {
    width: 100%;
  }
  .SliderHome .slick-dots {
    bottom: 30px;
  }
  .absoluteHeadingRight {
    right: 70px;
    top: -25px;
  }
  .absoluteHeadingRight h2 {
    font-size: 40px;
  }
  .LogosHome img {
    width: 90%;
  }
  .SliderHomeWardrobeMargin {
    margin: 80px 0 120px 0;
  }
  .SliderWardrobe h2 {
    font-size: 40px;
  }
  .TestimonialHome h2 {
    font-size: 22px;
  }
  .InsightsHome img {
    width: 100%;
  }
  .HomeCTA p {
    font-size: 16px;
  }
  .HomeCTA p br {
    display: none;
  }
  .HomeCTA_BG {
    padding: 80px 40px;
    margin: 70px 0 0 0;
  }
  .mapCode iframe{height: 350px;}
  .InstaHomeMargin h2 {
    margin-bottom: 40px;
  }
  .InstaHome {
    margin: 0 0 40px 0;
  }
  .InstaHome img {
    margin-bottom: 35px;
  }
  .marginBtn {
    margin-bottom: 90px;
  }
  .textModule2HomeMargin {
    margin: 70px 0 70px 0;
  }
  .InnerHeader {
    height: 330px !important;
  }
  .blockQuote blockquote br {
    display: none;
  }
  .ShowroomSlider img {
    width: 70%;
  }
  .custom_ul1 ul {
    font-size: 16px;
  }
  .NavFixed {
    display: none;
  }
  .custom_ul1 ul {
    padding-left: 30px;
  }
  .custom_ul1 ul li:before {
    background-size: 14px;
    left: -30px;
  }
  .Showroom {
    padding: 80px 0;
  }
  .teamTextBoxParent {
    position: relative;
  }
  .teamTextBox h3 {
    font-size: 32px;
  }
  .customArrowsBlack .slick-next {
    top: 40%;
  }
  .teamBox img {
    width: 100%;
  }
  .teamCarousel .slick-track {
    margin-bottom: 0;
  }
  .Team {
    padding-bottom: 20px;
  }
  .LogosAbout {
    margin-bottom: 40px;
  }
  .NextImage {
    margin: 60px 0;
  }
  .SliderText2 h2 {
    font-size: 40px;
  }
  .SliderText2 span {
    font-size: 16px;
  }
  .customArrowsWhite .slick-next {
    z-index: 99;
  }
  .AboutIntroText {
    padding: 60px 0;
  }
  .textModule3Margin {
    padding-bottom: 60px;
  }
  .InnerHeaderText h1 {
    margin-left: 0;
  }
  .pagedescInnerHeaderMargin{margin-left:0;}
  .blockQuote blockquote {
    font-size: 22px;
  }
  .blockQuote span {
    font-size: 14px;
  }
  .InnerHeaderText h1 {
    font-size: 45px;
  }
  .HistoryImg img {
    width: 80%;
  }
  .TextModuleLeftImage1 img {
    width: 100%;
  }
  .Imgbg:after {
    left: -10px;
    top: -10px;
  }
  .HistorySliderMargin {
    padding: 60px 0;
  }
  .TextModuleLeftImage1Margin {
    padding: 120px 0 40px 0;
  }
  .TextModuleRightImage1Margin {
    padding: 80px 0;
  }
  .LogoAndText img {
    width: auto !important;
  }
  .FourBlocksImg .col {
    flex: 0 0 25%;
    margin-right: 0;
  }
  .TextModuleLeftImage2 img {
    width: 100%;
  }
  .TextModuleLeftImage2Margin {
    padding: 120px 0 20px 0;
  }
  .QualityNav img {
    width: 100%;
  }
  .QualityIntroMargin {
    padding: 60px 0;
  }
  .QualityThreeCols .row .col-xl-4 {
    margin-bottom: 35px;
  }
  .QualityThreeCols .row .col-xl-4:nth-last-child(1) {
    margin-bottom: 0;
  }
  .fulwidth_Tabs .nav-tabs .nav-link {
    font-size: 16px;
  }
  .fulwidth_TabsMargin {
    margin: 60px 0 120px 0;
  }
  .fulwidth_Tabs .Imgbg img {
    width: 100%;
  }
  .PortfolioRowHome .col-xl-5:nth-child(2) .PortfolioBox h3,
  .PortfolioRowHome .col-xl-5:nth-child(2) .PortfolioBox span {
    margin-left: 0;
  }
  .postHeaderMargin {
    margin: 40px 0 0px 0;
  }
  .postContentTitle {
    font-size: 28px;
  }
  .postContent .size-full {
    margin-bottom: 50px;
    margin-top: 40px;
    width: 100%;
    height: 100%;
  }
  .postContent p,
  .postContent ul,
  .postContent ol,
  .postContent p span {
    padding: 0;
  }
  .btnBorderBig {
    padding: 20px 20px 18px 20px;
    font-size: 12px;
  }
  .postBigBtn {
    padding: 40px 0 60px 0;
  }
  .btnBorderMargin .btnBorder {
    margin-top: 10px;
  }
  .ImageHeader {
    height: 400px !important;
  }
  .contact_form {
    padding: 25px 30px;
  }
  .contact_form h2 {
    font-size: 24px;
  }
  .SocialMediaIconsContact h2 {
    text-align: center;
    margin-bottom: 20px !important;
  }
  .QualityNav ul li:nth-child(1){left:140px !important;}
  .QualityNav ul li:nth-child(1):before { top: 35px; left: 60px; height: 60px; }
  .QualityNav ul li:nth-child(1):after { top: 91px; left: 55px; }
  .QualityNav ul li:nth-child(2):before { top: 40px!important; left: 80px!important; height: 90px!important; }
  .QualityNav ul li:nth-child(2):after { top: 125px!important; left: 75px!important; }
  .QualityNav ul li:nth-child(3):before { left: 70px!important; height: 90px!important; top: 40px!important; }
  .QualityNav ul li:nth-child(3):after { top: 125px!important; left: 65px!important; }
  .QualityNav ul li:nth-child(4):before { top: -90px!important; left: 65px!important; height: 90px!important; }
  .QualityNav ul li:nth-child(4):after { top: -100px; left: 59px; }
  .QualityNav ul li:nth-child(5) { bottom: -20px; left: 50%; }
  .QualityNav ul li:nth-child(5):before { top: -60px; left: 60px; height: 70px; }
  .QualityNav ul li:nth-child(5):after { top: -60px; left: 55px; }
  .portfolioImg{
    height: 250px;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  .collectionBox .textModule1 p br{display:none;}
  .collectionBox.SystemCollection{padding-bottom:160px;}

  .valueBox{padding-right:50px;}
  .KeyFeaturesWardrobe.textModule1 .bigUl h3{font-size:24px;}
  .bigUl ul{font-size:20px;}


  .pagedescInnerHeaderMargin{
    padding: 25px 10px 10px 10px;
    background: rgba(71, 72, 73, 0.7);
    color: #fff;
  }
  .postBox .postThumb{width:100%;}
  .mapCode iframe{height:400px;}
  .VideoBanner{height:600px;}
  .Filters h5{font-size:16px;}
  .navbar-nav li a{font-size:16px;margin:0 6px;}
  .twoColImage a{height:300px;}
  .KitchenDetail .thumbBox, .portfolioDetail .thumbBox{height:450px;}
  .KitchenProBox h3{font-size:18px;}
  .navbar-brand img{width:150px;}
  .QualityNav ul li a{font-size:16px;}
  .QualityNav ul li:nth-child(1){left:140px !important;}
  .QualityNav ul li:nth-child(1):before{top:40px;left:70px;}
  .QualityNav ul li:nth-child(1):after { top: 185px; left:65px; }
  .QualityNav ul li:nth-child(2):before { top: 40px!important; left: 80px!important; height: 90px!important; }
  .QualityNav ul li:nth-child(2):after { top: 125px!important; left: 75px!important; }
  .QualityNav ul li:nth-child(3):before { left: 70px!important; height: 90px!important; top: 40px!important; }
  .QualityNav ul li:nth-child(3):after { top: 125px!important; left: 65px!important; }
  ._2R-kh{
    justify-content: center;
  }
  ._wPDyp{
    flex: 0 0 23.90%
  }
  .circleAttr {
    width: 30px;
    height: 30px;
  }
  .KitchenProBox_TextBoxMargin {
    margin-bottom: 20px;
    padding-bottom: 20px;
  }
  .textModule1 span {
    margin-top: 10px;
  }
  .LeftRightBoxesMargin {
    margin-top: 40px;
    padding-top: 40px;
  }
  .OrangeH6mb-20 h6 {
    margin: 12px 0 !important;
  }
  .squareDotsMargin .slick-dots {
    margin: 0 0 20px 0;
  }
  .enquireBtn {
    padding: 6px 15px;
    font-size: 12px;
  }
  .underlineBtn {
    font-size: 12px;
  }
  .KPbgImg {
    height: 600px !important;
  }
  .logoImg {
    width: 180px !important;
  }
  .BenefitsBlocks img {
    height: 100%;
    object-fit: cover;
  }
  .ThreeBlocks span br {
    display: none;
  }
  .imgHeightBenefits {
    height: 600px;
  }
  .fulwidth_Tabs .Imgbg img {
    width: 100%;
  }
  .QualityNav img {
    width: 100%;
  }
  .QualityIntroMargin {
    padding: 60px 0;
  }
  .QualityThreeCols .row .col-xl-4 {
    margin-bottom: 35px;
  }
  .QualityThreeCols .row .col-xl-4:nth-last-child(1) {
    margin-bottom: 0;
  }
  .textModule3Margin {
    padding-bottom: 60px;
  }
  .InnerHeaderText h1 {
    margin-left: 0;
  }
  .blockQuote blockquote {
    font-size: 22px;
  }
  .blockQuote span {
    font-size: 14px;
  }
  .InnerHeaderText h1 {
    font-size: 45px;
  }
  .HistoryImg img {
    width: 80%;
  }
  .TextModuleLeftImage1 img {
    width: 100%;
  }
  .Imgbg:after {
    left: -10px;
    top: -10px;
  }
  .HistorySliderMargin {
    padding: 60px 0;
  }
  .TextModuleLeftImage1Margin {
    padding: 120px 0 40px 0;
  }
  .TextModuleRightImage1Margin {
    padding: 80px 0;
  }
  /* .LogoAndText img{width:auto !important;} */
  .FourBlocksImg .col {
    flex: 0 0 25%;
    margin-right: 0;
  }
  .TextModuleLeftImage2 img {
    width: 100%;
  }
  .TextModuleLeftImage2Margin {
    padding: 120px 0 20px 0;
  }
  .TextModuleRightImage1 .Imgbg img {
    width: 100%;
  }
  .QualityNav ul li:nth-child(1) {
    left: 17px;
  }
  .QualityNav ul li:nth-child(2):before {
    top: 60px;
    left: 116px;
    height: 107px;
  }
  .QualityNav ul li:nth-child(2):after {
    top: 163px;
    left: 110px;
  }
  .QualityNav ul li:nth-child(3):before {
    top: 50px;
    left: 131px;
    height: 160px;
  }
  .QualityNav ul li:nth-child(3) {
    right: 20%;
  }
  .QualityNav ul li:nth-child(3):before {
    left: 90px;
  }
  .QualityNav ul li:nth-child(3):after {
    top: 205px;
  }
  .QualityNav ul li:nth-child(4) {
    left: 27%;
  }
  .QualityNav ul li:nth-child(4):before {
    top: -170px;
    height: 170px;
  }
  .QualityNav ul li:nth-child(4):after {
    top: -180px;
  }
  .QualityNav ul li:nth-child(5) {
    bottom: 10px;
    left: 37%;
  }
  .QualityNav ul li:nth-child(5):before {
    top: -55px;
    left: 70px;
    height: 60px;
  }
  .QualityNav ul li:nth-child(5):after {
    top: -60px;
    left: 65px;
  }
  .PortfolioRowHome .col-xl-5:nth-child(2) .PortfolioBox h3,
  .PortfolioRowHome .col-xl-5:nth-child(2) .PortfolioBox span {
    margin-left: 10px;
  }
  .postHeaderMargin {
    margin: 40px 0 0px 0;
  }
  .postContentTitle {
    font-size: 28px;
  }
  .postContent .size-full {
    margin-bottom: 50px;
    margin-top: 40px;
    width: 100%;
    height: 100%;
  }
  .postContent p,
  .postContent ul,
  .postContent ol,
  .postContent p span {
    padding: 0;
  }
  .btnBorderBig {
    padding: 20px 20px 18px 20px;
    font-size: 12px;
  }
  .postBigBtn {
    padding: 40px 0 60px 0;
  }
  .btnBorderMargin .btnBorder {
    margin-top: 10px;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .navbar-brand img{width:132px;}

}

/* Large devices (desktops, 992px and up) */
@media (min-width: 1200px) and (max-width: 1400px) {
  .valueBox{padding-right:50px;}
  .KeyFeaturesWardrobe.textModule1 .bigUl h3{font-size:24px;}
  .bigUl ul{font-size:20px;}

  .Imgbg:after{left:-10px;top:-10px;}
  .collectionBox .textModule1 p br{display:none;}
  .mapCode iframe{height:400px;}
  .VideoBanner{height:650px;}
  .KitchenDetail .thumbBox, .portfolioDetail .thumbBox{height: 600px;}
  .portfolioImg{
    height: 250px;
  }
  .circleAttr {
    width: 30px;
    height: 30px;
  }
  .KitchenProBox_TextBoxMargin {
    margin-bottom: 20px;
    padding-bottom: 20px;
  }
  .textModule1 span {
    margin-top: 10px;
  }
  .LeftRightBoxesMargin {
    margin-top: 40px;
    padding-top: 40px;
  }
  .OrangeH6mb-20 h6 {
    margin: 12px 0 !important;
  }
  .enquireBtn {
    padding: 10px 25px;
  }
  .squareDotsMargin .slick-dots {
    margin: 0 0 20px 0;
  }
  .BenefitsBlocks img {
    height: 100%;
    object-fit: cover;
  }
  .ThreeBlocks span br {
    display: none;
  }
  .imgHeightBenefits {
    height: 600px;
  }
  .QualityNav ul li:nth-child(1) {
    left: 44px;
  }
  .QualityNav ul li:nth-child(2) {
    top: 90px;
    left: 54%;
  }
  .QualityNav ul li:nth-child(2):before {
    top: 40px;
    left: 113px;
    height: 125px;
  }
  .QualityNav ul li:nth-child(2):after {
    top: 158px;
  }
  .QualityNav ul li:nth-child(3) {
    right: 17%;
  }
  .QualityNav ul li:nth-child(5) {
    bottom: 20px;
  }
  .QualityNav ul li:nth-child(5):before {
    top: -80px;
    left: 70px;
    height: 90px;
  }
  .QualityNav ul li:nth-child(5):after {
    top: -86px;
    left: 64px;
  }
  .PortfolioRowHome .col-xl-5:nth-child(2) .PortfolioBox h3,
  .PortfolioRowHome .col-xl-5:nth-child(2) .PortfolioBox span {
    margin-left: 80px;
  }
  .postHeaderMargin {
    margin: 40px 0 0px 0;
  }
  .postContentTitle {
    font-size: 28px;
  }
  .postContent .size-full {
    margin-bottom: 50px;
    margin-top: 40px;
    width: 100%;
    height: 100%;
  }
  .postContent p,
  .postContent ul,
  .postContent ol,
  .postContent p span {
    padding: 0;
  }
  .btnBorderBig {
    padding: 20px 20px 18px 20px;
    font-size: 12px;
  }
  .postBigBtn {
    padding: 40px 0 60px 0;
  }
  .btnBorderMargin .btnBorder {
    margin-top: 10px;
  }
  .LeftRightBoxesMargin {
    margin-top: 40px;
    padding-top: 40px;
  }
  .KPbgImg {
    height: 600px !important;
  }
  .logoImg {
    width: 180px !important;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1550px) {
  .TestimonialHomeMargin{margin-top:80px;}
  .LeftRightBoxesMargin {
    margin-top: 40px;
    padding-top: 40px;
  }
  .KPbgImg {
    height: 600px !important;
  }
  .logoImg {
    width: 280px !important;
  }
  .absoluteHeadingLeft {
    top: 0;
    left: 0;
  }
  .absoluteHeadingLeft h2 {
    font-size: 45px;
  }
  .PortfolioBox h3 a {
    font-size: 18px;
    margin-top: 45px;
  }
  .absoluteHeadingRight {
    right: 60px;
  }
  .absoluteHeadingRight h2 {
    font-size: 45px;
  }
  .SliderHome img {
    width: 100%;
  }
  .SliderHome .slick-dots {
    bottom: 40px;
  }
  .SliderHomeWardrobeMargin {
    margin: 90px 0 120px 0;
  }
  .SliderWardrobe h2 {
    font-size: 45px;
  }
  .InstaHome img {
    width: 100%;
  }
  .InstaHomeMargin h2 {
    margin-bottom: 40px;
  }
  .InsightsHome img {
    width: 100%;
  }
  .custom_ul1 ul {
    padding-left: 30px;
  }
  .custom_ul1 ul li:before {
    background-size: 14px;
    left: -30px;
  }
  .Showroom {
    padding: 80px 0;
  }
  /* .teamTextBoxParent{position:relative;} */
  .teamTextBox h3 {
    font-size: 32px;
  }
  .customArrowsBlack .slick-next {
    top: 40%;
  }
  .teamBox img {
    width: 80%;
  }
  .teamCarousel .slick-track {
    margin-bottom: 0;
  }
  .Team {
    padding-bottom: 20px;
  }
  .LogosAbout {
    margin-bottom: 40px;
    margin-top: 60px;
  }
  .NextImage {
    margin: 60px 0;
  }
  .SliderText2 h2 {
    font-size: 40px;
  }
  .SliderText2 span {
    font-size: 16px;
  }
  .customArrowsWhite .slick-next {
    z-index: 99;
  }
  .AboutIntroText {
    padding: 60px 0;
  }
  .textModule3Margin {
    padding-bottom: 60px;
  }
  .InnerHeaderText h1 {
    margin-left: 0;
  }
  .pagedescInnerHeaderMargin{margin-left: 0;}
  .blockQuote blockquote {
    font-size: 22px;
  }
  .blockQuote span {
    font-size: 14px;
  }
  .InnerHeader {
    height: 450px !important;
  }
  .ShowroomSlider img {
    width: 80%;
  }
  .blockQuote blockquote br {
    display: none;
  }
  .teamTextBoxParent {
    bottom: -60px;
    width: 400px;
  }
  .NavFixed {
    display: none;
  }
}

video::-webkit-media-controls {
  display: none;
}

@media (min-width: 1500px) {
  .container1500 {
    max-width: 1500px !important;
  }
  /* .WardrobeBox .textModule1 h2 {
    font-size: 30px;
  } */
}

@media (min-width: 1401px) and (max-width: 1600px){
  .KitchenDetail .thumbBox, .portfolioDetail .thumbBox{height:600px;}
}

.fade-in {
  -webkit-animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}
@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* .HistorySlider{
  display: none;
} */

@media (max-width: 767px) {
  .goTop{bottom:10px;right:inherit;left:30px;width:34px;height:52px;}
  .portfolio-result-title{font-size: 12px;text-transform:capitalize}
  .portfolio-result-location{font-size:12px;text-transform:capitalize;margin-bottom:0;}
  .portfolio-result p{padding-left:10px;margin-bottom:0;}
  .results-list > li > a{text-decoration: none; color: #000; border-bottom: 1px solid #000; padding-bottom: 3px; display: inline-block; margin-bottom: 12px; font-size: 12px;}
  .headerColorLogo{width: 100%;}
  .PaddingTopHeding{padding-top:78px;}
  .change_header .navbar-brand {
    display: none;
  }
  .change_header {
    height: 100%;
  }
  .change_header .mobileHeader {
    display: block !important;
  }
  .mobileHeader .navbar-toggler {
    display: none;
  }
  .change_header .col-2 .navbar-toggler {
    display: none !important;
  }
  .change_header .mobileHeader .navbar-toggler {
    display: block !important;
  }
  .mobileHeader .navbar-toggler {
    padding-left: 0;
  }
  .mobile-menu-opened {
    display: block !important;
  }
  .navbar-open .navbar-toggler-icon:before,
  .navbar-open .navbar-toggler-icon:after {
    width: 70% !important;
  }
  .change_header .mobileHeader {
    display: flex !important;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 25px 40px 25px;
  }
  .mobileHeader .phoneLinkMobile {
    text-align: right;
    padding-right: 15px;
  }
  .mobileHeader .phoneLinkMobile svg {
    width: 16px;
  }
  .mobileHeader .phoneLinkMobile svg path {
    fill: rgba(0, 0, 0, 0.4);
  }
  .HeaderSocialIcons svg path {
    fill: rgba(0, 0, 0, 0.4) !important;
  }
  .HeaderSocialIcons a:hover svg path, .HeaderSocialIcons a:focus svg path{
    fill: rgb(0, 0, 0) !important;
  }
  .HeaderSocialIcons a{
    display: inline-block;
    margin: 0 8px;
  }
  .HeaderSocialIcons a:nth-child(1) {
    margin-left: 0;
  }
  .HeaderSocialIcons svg {
    width: 16px;
    height: 16px;
  }
  .HeaderSocialIcons a:focus{
    color:#000;
  }
  
  .WhatsappHeader svg {
    width: 30px;
    height: 30px;
  }
  .WhatsappHeader svg path {
    fill: #000 !important;
  }
  /* .change_header .w-100{ padding: 0 30px 0 25px; height: 90vh; } */
  header .w-100,
  .GoogleMap .w-100 {
    padding: 0 20px;
  }
  .mobileHeader {
    height: 100%;
  }
  .Hero {
    margin-top: 95px;
    background-position: center;
    height: 50vh;
  }
  .searchBarMobile input[type="text"] {
    width: 100%;
    padding: 7px 20px;
    border-radius: 0;
    border: 1px solid rgba(0, 0, 0, 0.2);
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE5LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPg0KPHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB2aWV3Qm94PSIwIDAgNTEyLjAwNSA1MTIuMDA1IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA1MTIuMDA1IDUxMi4wMDU7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxnPg0KCTxnPg0KCQk8cGF0aCBkPSJNNTA1Ljc0OSw0NzUuNTg3bC0xNDUuNi0xNDUuNmMyOC4yMDMtMzQuODM3LDQ1LjE4NC03OS4xMDQsNDUuMTg0LTEyNy4zMTdjMC0xMTEuNzQ0LTkwLjkyMy0yMDIuNjY3LTIwMi42NjctMjAyLjY2Nw0KCQkJUzAsOTAuOTI1LDAsMjAyLjY2OXM5MC45MjMsMjAyLjY2NywyMDIuNjY3LDIwMi42NjdjNDguMjEzLDAsOTIuNDgtMTYuOTgxLDEyNy4zMTctNDUuMTg0bDE0NS42LDE0NS42DQoJCQljNC4xNiw0LjE2LDkuNjIxLDYuMjUxLDE1LjA4Myw2LjI1MXMxMC45MjMtMi4wOTEsMTUuMDgzLTYuMjUxQzUxNC4wOTEsNDk3LjQxMSw1MTQuMDkxLDQ4My45MjgsNTA1Ljc0OSw0NzUuNTg3eg0KCQkJIE0yMDIuNjY3LDM2Mi42NjljLTg4LjIzNSwwLTE2MC03MS43NjUtMTYwLTE2MHM3MS43NjUtMTYwLDE2MC0xNjBzMTYwLDcxLjc2NSwxNjAsMTYwUzI5MC45MDEsMzYyLjY2OSwyMDIuNjY3LDM2Mi42Njl6Ii8+DQoJPC9nPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPC9zdmc+DQo=)
      no-repeat;
    background-position: right 35px center;
    background-size: 15px;
    outline: none;
    transition: 0.5s;
    opacity: 0.4;
  }
  .mobileHeader .navbar-collapse {
    flex-basis: 65%;
  }
  .searchBarMobile input[type="text"]:focus {
    border: 1px solid rgba(0, 0, 0, 0.7);
    transition: 0.5s;
  }
  .navbar-toggler .navbar-toggler-icon {
    height: 1px;
    width: 22px;
    background-color: #000;
    position: relative;
  }
  .navbar-toggler-icon:before {
    position: absolute;
    content: "";
    background: #000;
    height: 1px;
    width: 100%;
    top: -7px;
    left: 0;
    transition: 0.5s;
  }
  .navbar-toggler-icon:after {
    position: absolute;
    content: "";
    background: #000;
    height: 1px;
    width: 100%;
    bottom: -7px;
    left: 0;
    transition: 0.5s;
  }
  .navbar-toggler {
    box-shadow: none !important;
  }
  .navbar-open .navbar-toggler-icon {
    background: transparent;
    top: 5px;
  }
  .navbar-open .navbar-toggler-icon:before {
    transform: rotate(135deg);
    transition: 0.5s;
    width: 90%;
  }
  .navbar-open .navbar-toggler-icon:after {
    transform: rotate(-135deg);
    bottom: 7px;
    width: 90%;
    transition: 0.5s;
  }
  .navbar-nav {
    display: block !important;
  }
  .navbar-nav li {
    display: block !important;
  }
  .navbar-nav li a {
    margin: 20px 0 0 0;
    display: block;
    font-size: 22px;
    /* color: rgba(36, 36, 36, 0.7); */
    color: #272727;
  }
  .change_header {
    height: 100% !important;
    background: #fff !important;
  }
  .LogosHomeSlider.slick-slider .slick-slide div {
    text-align: center !important;
  }
  .LogosHomeSlider.slick-slider .slick-slide div img {
    display: inline-block !important;
    width: 80% !important;
    margin: auto;
  }
  .SliderText img {
    width: 100%;
  }
}

/* .HeroSlider .slick-track .slick-slide:nth-child(1) .zoom-images{
  height: 100%;
} */

.Filters .container  .row .filter-dropdown:nth-child(5) .FilterList ul li{
  flex: 0 0 100%;
}

.HeroSlider .slick-track .slick-slide:nth-child(even) .zoom-images .animatedImage0{
  animation: zoom-slider-images0 50s infinite !important;
}

@keyframes zoom-slider-images0 {
  0% {
     -webkit-transform:scale(1); /* Safari and Chrome */
    -moz-transform:scale(1); /* Firefox */
    -ms-transform:scale(1); /* IE 9 */
    -o-transform:scale(1); /* Opera */
    transform:scale(1) translate(0, 0px);
    background-position: center;
    background-size: 100%;
    
    
  }
  50% {
     -webkit-transform:scale(1.3); /* Safari and Chrome */
    -moz-transform:scale(1.3); /* Firefox */
    -ms-transform:scale(1.3); /* IE 9 */
    -o-transform:scale(1.3); /* Opera */
    transform:scale(1.3) translate(100px, -100px);
    background-size: 150%;
    background-position: center; 
    
  }
  100% {
     -webkit-transform:scale(1); /* Safari and Chrome */
    -moz-transform:scale(1); /* Firefox */
    -ms-transform:scale(1); /* IE 9 */
    -o-transform:scale(1); /* Opera */
    transform:scale(1) translate(0, 0);
    background-size: 100%;
    background-position: center; 
    
  }
  
}


.HeroSlider .slick-track .slick-slide:nth-child(odd) .zoom-images .animatedImage{
  animation: zoom-slider-images1 50s infinite !important;
}

@keyframes zoom-slider-images1 {
  0% {
     -webkit-transform:scale(1); /* Safari and Chrome */
    -moz-transform:scale(1); /* Firefox */
    -ms-transform:scale(1); /* IE 9 */
    -o-transform:scale(1); /* Opera */
    transform:scale(1);
    background-size: 100%;
    background-position: center; 
  }
  50% {
     -webkit-transform:scale(1.5); /* Safari and Chrome */
    -moz-transform:scale(1.5); /* Firefox */
    -ms-transform:scale(1.5); /* IE 9 */
    -o-transform:scale(1.5); /* Opera */
    transform:scale(1.5) translate(-350px, 50px);
    background-size: 150%;
    background-position: center; 
  }
  100% {
     -webkit-transform:scale(1); /* Safari and Chrome */
    -moz-transform:scale(1); /* Firefox */
    -ms-transform:scale(1); /* IE 9 */
    -o-transform:scale(1); /* Opera */
    transform:scale(1) translate(0, 0);
    background-size: 100%;
    background-position: center; 
  }
}

.HeroSlider .slick-track .slick-slide .zoom-images .animatedImage{
  animation: zoom-slider-images2 50s infinite !important;
}

@keyframes zoom-slider-images2{
  0% {
     -webkit-transform:scale(1); /* Safari and Chrome */
    -moz-transform:scale(1); /* Firefox */
    -ms-transform:scale(1); /* IE 9 */
    -o-transform:scale(1); /* Opera */
    transform:scale(1);
    background-position: center; 
  }
  50% {
     -webkit-transform:scale(1.3); /* Safari and Chrome */
    -moz-transform:scale(1.3); /* Firefox */
    -ms-transform:scale(1.3); /* IE 9 */
    -o-transform:scale(1.3); /* Opera */
    transform:scale(1.3) translate(100px, 150px);
    background-size: 200%;
    background-position: center; 
  }
  100% {
     -webkit-transform:scale(1); /* Safari and Chrome */
    -moz-transform:scale(1); /* Firefox */
    -ms-transform:scale(1); /* IE 9 */
    -o-transform:scale(1); /* Opera */
    transform:scale(1) translate(0, 0);
    background-position: center; 
  }
}

.kitchen-icons-links{
  color: #686868;
}

.kitchen-icons-links:hover{
  color: #000;
}

.kitchen-icons-terms{
  color:#686868;
  font-family: "bsl";
  font-size: 16px;
}

div.kitchen-icons-loadmore{
  display:flex;
  justify-content: center;
}

div.kitchen-icons-loadmore > button{
  color: #505050;
  font-size: 14px;
  font-family: "bsl";
  border: 1px solid #d9d9d9;
  display: inline-block;
  padding: 14px 40px;
  text-transform: uppercase;
  transition: 0.5s;
}
div.kitchen-icons-loadmore > button:hover{
  background: #ec6707;
  border: 1px solid transparent;
  color: #fff;
}
div.kitchen-icons-loadmore > button:focus{
  box-shadow: none;
}

.Submenu > .container{
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.submenu-select{
  display:none;
  width: 85vw;
  height: 35px;
  padding: 0px 10px;
  background: transparent;
  color: #aeaeae;
  margin: 5px auto;
  border: 1px solid #aeaeae;
  font-family: bsl;
}
.submenu-flex{
  display: flex !important;
  flex-wrap:wrap;
  justify-content: center;
}

@media screen and (max-width: 767px) {
  .submenu-select{
    display:inline-block;
  }
  .container.submenu-with-dropdown{
    display: flex
  }
  ul.submenu-with-dropdown{
    display:none !important;
  }
}

.kithcenBanner{
  height: 80vh;
}

.portfolio-heading{
font-size: 35px;
color: #272727;
text-transform: inherit;
margin-bottom: 0;

  font-family: bsl;
  -webkit-font-smoothing: antialiased;
  text-align: center;

}

.phoneInput{
  font-family: 'bsl' !important;
  padding: 0 !important;
  width: 100% !important;
  background: #f9f9fc !important;
  /* border: 0 !important; */
  border-radius: 1px !important;
}

.phoneInput .form-control{
  width: 100% !important;
  border-radius: 0 !important;
  border: 0 !important;
  background: #f9f9fc !important;
}

.phoneInput .selected-flag{
  border: 0 !important;
  border-radius: 3px 0 0 3px !important;
}
.phoneInput .flag-dropdown{
border-radius: 0 !important;
}